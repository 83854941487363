
import React from "react";

import {OuterTextContainer, TextContainer, TextOnlyContainer, Text} from "../components/styledcomponents";

import plakat from "../img/A4_Digital.jpg";
import ogtitle from "../img/titleimage.jpg";

import image01 from "../img/01AufmacherBild/01Aufmacher_webfinal.jpg";
import image02 from "../img/01DerLockdown/01DerLockdown_webfinal.jpg";
import image03 from "../img/01DerLockdown/02DerLockdown_webfinal.jpg";
import image04 from "../img/01DerLockdown/03DerLockdown_webfinal.jpg";
import image05 from "../img/01DerLockdown/04DerLockdown_webfinal.jpg";
import image06 from "../img/01DerLockdown/05DerLockdown_webfinal.jpg";
import image07 from "../img/01DerLockdown/06DerLockdown_webfinal.jpg";
import image08 from "../img/01DerLockdown/07DerLockdown_webfinal.jpg";
import image09 from "../img/01DerLockdown/08DerLockdown_webfinal.jpg";
import image10 from "../img/01DerLockdown/09DerLockdown_webfinal.jpg";
import image11 from "../img/01DerLockdown/10DerLockdown_webfinal.jpg";
import image12 from "../img/01DerLockdown/11DerLockdown_webfinal.jpg";
import image13 from "../img/01DerLockdown/12DerLockdown_webfinal.jpg";

import image14 from "../img/02DieAerztinVerenaWerling/13VerenaWerling_webfinal.jpg";
import image15 from "../img/02DieAerztinVerenaWerling/14VerenaWerling_webfinal.jpg";
import image16 from "../img/02DieAerztinVerenaWerling/15VerenaWerling_webfinal.jpg";
import image17 from "../img/02DieAerztinVerenaWerling/16VerenaWerling_webfinal.jpg";
import image18 from "../img/02DieAerztinVerenaWerling/17VerenaWerling_webfinal.jpg";
import image19 from "../img/02DieAerztinVerenaWerling/18VerenaWerling_webfinal.jpg";
import image20 from "../img/02DieAerztinVerenaWerling/19VerenaWerling_webfinal.jpg";
import image21 from "../img/02DieAerztinVerenaWerling/20_1VerenaWerling_webfinal.jpg";
import image22 from "../img/02DieAerztinVerenaWerling/20_2VerenaWerling_webfinal.jpg";
import image23 from "../img/02DieAerztinVerenaWerling/21VerenaWerling_webfinal.jpg";

import image24 from "../img/03DiePflege/22VerenaWerling_webfinal.jpg";
import image25 from "../img/03DiePflege/23VerenaWerling_webfinal.jpg";
import image26 from "../img/03DiePflege/24VerenaWerling_webfinal.jpg";
import image27 from "../img/03DiePflege/25VerenaWerling_webfinal.jpg";
import image28 from "../img/03DiePflege/26VerenaWerling_webfinal.jpg";
import image29 from "../img/03DiePflege/27VerenaWerling_webfinal.jpg";
import image30 from "../img/03DiePflege/28VerenaWerling_webfinal.jpg";
import image31 from "../img/03DiePflege/29VerenaWerling_webfinal.jpg";
import image32 from "../img/03DiePflege/30VerenaWerling_webfinal.jpg";
import image33 from "../img/03DiePflege/31VerenaWerling_webfinal.jpg";
import image34 from "../img/03DiePflege/32VerenaWerling_webfinal.jpg";
import image35 from "../img/03DiePflege/33VerenaWerling_webfinal.jpg";
import image36 from "../img/03DiePflege/34VerenaWerling_webfinal.jpg";
import image37 from "../img/03DiePflege/35VerenaWerling_webfinal.jpg";
import image38 from "../img/03DiePflege/36VerenaWerling_webfinal.jpg";
import image39 from "../img/03DiePflege/37VerenaWerling_webfinal.jpg";
import image40 from "../img/03DiePflege/38VerenaWerling_webfinal.jpg";
import image41 from "../img/03DiePflege/39VerenaWerling_webfinal.jpg";
import image42 from "../img/03DiePflege/40VerenaWerling_webfinal.jpg";
import image43 from "../img/03DiePflege/41VerenaWerling_webfinal.jpg";
import image44 from "../img/03DiePflege/42VerenaWerling_webfinal.jpg";
import image45 from "../img/03DiePflege/43VerenaWerling_webfinal.jpg";
import image46 from "../img/03DiePflege/44VerenaWerling_webfinal.jpg";
import image47 from "../img/03DiePflege/45VerenaWerling_webfinal.jpg";
import image48 from "../img/03DiePflege/46VerenaWerling_webfinal.jpg";
import image49 from "../img/03DiePflege/47VerenaWerling_webfinal.jpg";
import image50 from "../img/03DiePflege/48VerenaWerling_webfinal.jpg";

import image51 from "../img/04DerschwereVerlauf/49Wenzel_webfinal.jpg";
import image52 from "../img/04DerschwereVerlauf/50Wenzel_webfinal.jpg";
import image53 from "../img/04DerschwereVerlauf/51Wenzel_webfinal.jpg";
import image54 from "../img/04DerschwereVerlauf/52Wenzel_webfinal.jpg";
import image55 from "../img/04DerschwereVerlauf/53Wenzel_webfinal.jpg";
import image56 from "../img/04DerschwereVerlauf/54Wenzel_webfinal.jpg";
import image57 from "../img/04DerschwereVerlauf/55Wenzel_webfinal.jpg";
import image58 from "../img/04DerschwereVerlauf/56Wenzel_webfinal.jpg";
import image59 from "../img/04DerschwereVerlauf/57Wenzel_webfinal.jpg";
import image60 from "../img/04DerschwereVerlauf/58Wenzel_webfinal.jpg";
import image61 from "../img/04DerschwereVerlauf/59Wenzel_webfinal.jpg";
import image62 from "../img/04DerschwereVerlauf/60Wenzel_webfinal.jpg";
import image63 from "../img/04DerschwereVerlauf/61Wenzel_webfinal.jpg";
import image64 from "../img/04DerschwereVerlauf/62Wenzel_webfinal.jpg";
import image65 from "../img/04DerschwereVerlauf/63Wenzel_webfinal.jpg";
import image66 from "../img/04DerschwereVerlauf/64Wenzel_webfinal.jpg";

import image67 from "../img/05DieneueNormalitaet/65NeueNormalitaet_webfinal.jpg";
import image68 from "../img/05DieneueNormalitaet/66NeueNormalitaet_webfinal.jpg";
import image69 from "../img/05DieneueNormalitaet/67NeueNormalitaet_webfinal.jpg";
import image70 from "../img/05DieneueNormalitaet/68NeueNormalitaet_webfinal.jpg";
import image71 from "../img/05DieneueNormalitaet/69NeueNormalitaet_webfinal.jpg";
import image72 from "../img/05DieneueNormalitaet/70NeueNormalitaet_webfinal.jpg";
import image73 from "../img/05DieneueNormalitaet/71NeueNormalitaet_webfinal.jpg";
import image74 from "../img/05DieneueNormalitaet/72NeueNormalitaet_webfinal.jpg";
import image75 from "../img/05DieneueNormalitaet/73NeueNormalitaet_webfinal.jpg";
import image76 from "../img/05DieneueNormalitaet/74NeueNormalitaet_webfinal.jpg";

const sectionDefaultHeight = window.innerHeight > 600 ? "130vh" : "180vh";

export const content = [
  {
    sectionId: "Aufmacher",
    height: sectionDefaultHeight,
    image: image01,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined tall><b>„Ich will noch nicht sterben.“</b></Text>
        <Text>Sagt Wenzel D. zu seiner Frau Mirka. Er hat sich mit Covid-19 infiziert und wurde ins Marienhospital in Stuttgart eingeliefert. Wenige Stunden später wird er in ein künstliches Koma versetzt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "ThereIsGloryInPrevention",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text xl underlined>»There is glory { window.innerWidth > 700 ? <br/> : null } in prevention«</Text>
          <Text tall>
            Die Corona-Pandemie beherrscht unser Leben, dennoch bleibt die Gefahr für die meisten abstrakt. Mit dem Fotoprojekt »There is glory in prevention« möchte der Stuttgarter Fotojournalist Patrick Junker die Pandemie sichtbar machen. Seit Beginn der Krise im Frühjahr 2020 ist er vor allem in Stuttgart unterwegs. Seine Bilder und die Stimmen der Porträtierten, aufgezeichnet von Autoren der Reportergemeinschaft Zeitenspiegel Reportagen in Weinstadt, zeigen persönliche Geschichten der Krise, von Menschen, die täglich der Gefahr des Virus ausgesetzt sind, von Pflegekräften und Ärzten, von einem Patienten, der noch heute mit den Folgen seiner schweren Covid-19 Erkrankung kämpft. Sie erzählen von der neuen Normalität – und von Zeichen des Zusammenhalts.
            <br/><br/>
            Eine Chronologie der Pandemie.
            <br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "Kapitel-1-Der-Lockdown",
    height: "60vh",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text xl underlined>Kapitel 1</Text>
          <Text xl>Der Lockdown</Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "s04",
    height: sectionDefaultHeight,
    image: image02,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 23. März 2020</Text>
        <Text tall>Der Marktplatz vor dem Rathaus: Bundesweit treten Kontaktbeschränkungen in Kraft. In Baden-Württemberg dürfen sich ab diesem Tag im öffentlichen Raum nur noch zwei Personen aus unterschiedlichen Haushalten treffen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s05",
    height: sectionDefaultHeight,
    image: image03,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 23. März 2020</Text>
        <Text tall>Die Edeka-Filiale Fleck in Stuttgart-Fasanenhof öffnet für besonders gefährdete Personen eine Stunde früher. Ältere Menschen und solche mit Vorerkrankungen können so sicherer einkaufen. Um Hamsterkäufe zu vermeiden, limitieren erste Supermärkte in Deutschland den Verkauf von Toilettenpapier und anderen Produkten auf „haushaltsübliche Mengen“.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s06",
    height: sectionDefaultHeight,
    image: image04,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Rheinau-Freistett, 26. März 2020</Text>
        <Text tall>Die Bundespolizei kontrolliert seit zehn Tagen die deutsch-französische Grenze. Für Reisende ohne triftigen Reisegrund ist die Einreise verboten. Nur Berufspendler dürfen die Grenze passieren.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s07",
    height: sectionDefaultHeight,
    image: image05,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>„Meine Mutter ist Risikopatientin. Darum habe ich sie in der Zeit der Kontaktbeschränkungen nicht gesehen. Ich habe sie sehr vermisst. Generell ist es mir schwergefallen, dass Treffen mit Familienangehörigen stark eingeschränkt und manchmal unmöglich waren.“</b></Text>
        <Text>Nic Seiboldt arbeitet als Spezialreiniger und Desinfektor bei Akut SOS Clean, einem Betrieb für Desinfektion, Schädlingsbekämpfung und Dekontamination mit Sitz in Bad Soden am Taunus. Mit seinen Kollegen reinigt er Regierungsflieger, Bahnhöfe, Pflegeheime, Rettungswägen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s08",
    height: sectionDefaultHeight,
    image: image06,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>Marcell Engel, 47, Tatortreiniger und Leiter von Akut SOS Clean</b></Text>
        <Text tall>Während der ersten Welle der Corona-Pandemie waren er und seine Mitarbeiter im Dauereinsatz.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s08_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Wenn wir im Einsatz sind, tragen wir einen Virenschutzanzug. Dazu kommen Chemieschutzhandschuhe und dichte FFP3-Atemschutzmasken. Diese Montur sieht dramatisch aus, doch aus unserer professionellen Sicht als Reiniger ist Corona keine Herausforderung. Ich bin angstfrei. Grundsätzlich finde ich aber, wir dürfen keine Infektionskrankheit auf die leichte Schulter nehmen. Egal, ob es um eine Erkältung, Grippe oder Covid-19 geht. Hände waschen, den Flächenkontakt in den öffentlichen Verkehrsmitteln vermeiden: All das rate ich jedem, immer.
          <br/><br/>
          Mit der Corona-Pandemie hatte sich unser Arbeitsalltag komplett verändert. Im März gingen mehr als 5000 Anrufe ein. Viele verzweifelte Senioren meldeten sich, wollten bei uns Desinfektionsmittel kaufen, als es in den Supermärkten kein Hygiene-Gel mehr gab. Zu unseren Kunden gehören Privatpersonen, Unternehmen, Behörden, das Militär. Im Auftrag der Bundesregierung sind wir Teil der Pandemieprävention für Massenverkehrsmittel. Wir reinigten Regierungsflieger, Bahnhöfe, Pflegeheime, Fitnessstudios vor der Wiedereröffnung, Rettungswägen.
          <br/><br/>
          Besonders nahe ging mir die Evakuierung eines Seniorenheims. Ein Pfleger hatte sich infiziert, die Lage war ernst. Während wir die Unterkunft reinigten, mussten die Bewohner behelfsmäßig untergebracht werden, darunter viele bettlägerige Menschen. Da mussten wir nicht nur als Desinfektoren, sondern als Seelsorger auftreten.
          <br/><br/>
          Im Jahr 2014 war ich in Westafrika. Während der Ebola-Epidemie unterstützte ich die Arbeit der Helfer in Sierra Leone. Und erlebte mit, wie die Menschen vor meinen Augen starben. Alles war ungewohnt, die Versorgung vor Ort, der Arbeitsschutz, das Material miserabel. Während der Coronakrise habe ich das Sterben nicht direkt erlebt. Aber ich habe zwei Kollegen, Tatortreiniger in Bergamo und New York, die mir verdeutlichten, wie schrecklich die Situation andernorts war, gerade in jenen Ländern mit einem schwächeren Gesundheitssystem.
          <br/><br/>
          Wir haben Mitarbeiter, die sind über sechzig, Familienväter. Was wir als Team geleistet haben in den vergangenen Monaten, ist unglaublich. Oft kamen wir an unsere Grenzen, weil die Belastung enorm war. Wir hatten lange Tage, viele Überstunden. Doch wir haben einen sehr dankbaren Job: Weil wir helfen dürfen. Nur leider nicht immer können. Was mich besorgt, sind die wirtschaftlichen Konsequenzen, dass so viele Firmen und Unternehmer vor der Pleite stehen.
          <br/><br/>
          Wir hatten Anrufer, die ihre letzte Hoffnung auf uns setzten, die glaubten, wenn wir den Laden desinfizieren, läuft ihr Geschäft wieder. Was mich am meisten erschütterte: Wir hatten Kunden, die erst anriefen, um sich beraten zu lassen. Und am Ende mussten wir als Tatortreiniger anrücken. Suizid. In drei Fällen waren wir damit konfrontiert.
          <br/><br/>
          Bei vielen ist in dieser Zeit aber auch eine Rückbesinnung fühlbar. Solidarität ist spürbar – trotz der Sorgen und Zukunftsängste, die viele gerade plagen.
          <br/><br/>
          Mittlerweile hat sich unsere Situation normalisiert. Ich bin im Forschungskreis des Friedrich-Loeffler-Instituts zum Schutz vor Infektionskrankheiten und des Robert-Koch-Instituts, berate oft Politiker. Was wir heute wissen: Eine vernünftige Reinigung und Desinfektion ist bei Covid-19 ausreichend. Dafür braucht es uns als Fachexperten nicht unbedingt. Gründlichkeit reicht.
          <br/><br/>
          Eine persönliche Lehre, die ich aus dieser Krise ziehe: Gesundheit, Familie, Liebe, Freundschaft – das zählt. Haben wir das, dann können wir uns glücklich schätzen.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s09a",
    height: "20vh"
  },
  {
    sectionId: "s09",
    height: sectionDefaultHeight,
    image: image07,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Bad Soden am Taunus, 8. April 2020</Text>
        <Text tall>Marcell Engel (Mitte) und zwei Kollegen desinfizieren ein Taxi. Der Fahrer hat zu dieser Zeit kaum Kunden. Viele seiner Fahrgäste, meist Senioren, bleiben zuhause.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s10",
    height: sectionDefaultHeight,
    image: image08,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Dachsenhausen, 9. April 2020</Text>
        <Text tall><b>„Bei der stillen Beerdigung sind keine Trauernden dabei. Die Angehörigen nehmen später Abschied, jeder für sich.“</b></Text>
        <Text>Der Beisetzer Udo Feller an einem Urnengrab. Da während Beerdigungen oft körperliche Nähe gesucht wird, dürfen Angehörige nicht teilnehmen, um die Ansteckungsgefahr zu verringern.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s11",
    height: sectionDefaultHeight,
    image: image09,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 10. April 2020</Text>
        <Text tall>Der Woodpecker-Trail, eine Downhill-Strecke bei Stuttgart-Degerloch, ist gesperrt. Um Unfälle zu verhindern, die im Krankenhaus behandelt werden müssten, soll während des Lockdowns auf risikoreiche Sportarten verzichtet werden.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s12",
    height: sectionDefaultHeight,
    image: image10,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>Andreas Weller, 50, Tenor aus Stuttgart</b></Text>
        <Text tall>bereitet sich mit dem Organisten Kay Johannsen auf eine der Online-Andachten in der Stuttgarter Stiftskirche vor, die sie während des Corona-Lockdowns musikalisch gemeinsam gestalten. Erst ab dem 3. Mai dürfen in Baden-Württemberg wieder Gottesdienste stattfinden – nach über einem Monat Pause.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s12_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Kirchen übten immer schon eine besondere Faszination auf mich aus, die andächtige Stimmung, das gemeinsame Singen. Plötzlich vor leeren Kirchenbänken zu stehen, fühlte sich fremd an. Drei Mal pro Wochen trafen wir uns, um eine Andacht aufzuzeichnen. Es waren Fixpunkte in einem Alltag, der keiner mehr war. Wir wollten ein kleines Stückchen Normalität schaffen: Für Kranke, jene, die zur Risikogruppe zählen, sich um Angehörige sorgen. Für die, die ihre Arbeit verloren haben, die Zukunft fürchten, die Einsamen.
          <br/><br/>
          Um ein Gefühl von Verlässlichkeit zu transportieren, wählten wir vertraute Kirchenlieder, „Gelobet sei der Herr“ oder „Freunde, dass der Mandelzweig“. Prälatin Gabriele Arnold und Stiftspfarrer Matthias Vosseler gingen in ihren Ansprachen auf die aktuelle Lage ein. Die Videos, gefilmt mit einem Smartphone, stellten wir auf YouTube. Bisweilen zählten wir 1500 Klicks, erreichten so weit mehr Menschen, als mit einem üblichen Gottesdienst. Aus allen Ecken Deutschlands bedankten sich Menschen dafür. Eine Frau schrieb aus Südamerika: Für sie sei es tröstlich, aus der Ferne zu verfolgen, was in ihrer Heimatgemeinde vor sich geht. Es ist eine Zeit, in der sich viele von uns auf ihre Wurzeln besinnen.
          <br/><br/>
          Singen ist für mich besonders in diesen Zeiten elementar. Kann ich mich mit Musik nicht äußern, geht es mir nicht gut. Uns Künstler traf die Krise hart. Mit einem Schlag war alles weg, sämtliche Auftritte abgesagt. Und doch zähle ich zu den Privilegierten. Ich lebe mit meiner Familie in einem Haus mit Garten, nahe am Wald. Die freie Zeit nutze ich und gebe an der wieder geöffneten Waldorfschule Gesangsunterricht. Ich hatte Glück und bekam vom Land finanzielle Unterstützung. Meine Frau ist ebenfalls berufstätig. Als Ärztin am Klinikum Stuttgart war sie auch in der Corona-Ambulanz eingesetzt, während ich zusammen mit unseren vier Kindern viel Zeit zuhause verbrachte.
          <br/><br/>
          Treten außergewöhnliche Ereignisse auf, wirken Krisen auf uns Menschen, durchleben wir verschiedene Phasen. Erst wollen wir es nicht glauben, verdrängen. Dann versuchen wir, dagegen anzukämpfen. Irgendwann kommt der Punkt, an dem wir akzeptieren und versuchen müssen, damit zu leben. Was gerade für Spannungen sorgt in unserer Gesellschaft, ist, dass noch nicht alle Menschen die letzte Phase erreicht haben.
          <br/><br/>
          Dass jene, die es wirtschaftlich hart getroffen hat, viel mehr in Frage stellen, welche Maßnahmen nötig waren und sind, finde ich nachvollziehbar.  Heute sagen die Wissenschaftler so, morgen sagen sie so, kritisieren viele. Das ist das Wesen der Wissenschaft. Wir lernen gerade alle. Und dazu gehören auch Fehler. Ich finde es gut, dass Verordnungen erlassen, durch Gerichte überprüft und dann auch zurückgenommen werden. Einen besseren Beweis dafür, dass unser Staat funktioniert, gibt es doch gar nicht.
          <br/><br/>
          Das Virus wird nicht verschwinden. Für mich gehört darum nun auch dazu, immer wieder zu diskutieren: Wo haben wir aus Panik und Unwissen vielleicht auch den Bogen überspannt? Wo widersprechen sich Verordnungen zum Teil sogar oder sind nicht nachvollziehbar. An diesen Stellen muss nachgesteuert werden und wird es auch schon. Es wird auf alle Fälle weitergehen, wenn auch anders. Wir Künstler werden weiterhin Formate finden, kreativ mit der Krise umzugehen.
          <br/><br/>
          Vielleicht, so empfinde ich es manchmal, geht es uns ein wenig wie Noah auf seiner Arche. Wir würden gerne raus, können aber gerade nicht. Um uns herum tobt der Sturm. Doch irgendwann bricht die Sonne wieder hervor.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s13a",
    height: "20vh"
  },
  {
    sectionId: "s13",
    height: sectionDefaultHeight,
    image: image11,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Neubiberg im Landkreis München, 29. April 2020</Text>
        <Text tall>Ein Soldat führt bei einem Pressetermin vor, wie er Desinfektionsmittel herstellt. Der Freistaat Bayern hat die ABC-Abwehrkräfte der Bundeswehr gebeten, 200 Tonnen Flächendesinfektionsmittel zu produzieren. Bayern hat an diesem Tag mit 41.830 bestätigten Fällen die meisten Infizierten in Deutschland.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s14",
    height: sectionDefaultHeight,
    image: image12,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined tall><b>„Generell ist es meine Pflicht, dem deutschen Volk zu dienen. Darüber hinaus ist es mir ein großes Anliegen, meinen Beitrag im Einsatz gegen Covid-19 zu leisten.“</b></Text>
        <Text>Alexander M. ist ABC-Abwehrsoldat und Militärkraftfahrer. Normalerweise ist er in Bruchsal bei Karlsruhe stationiert. Bei dem Einsatz in Bayern kümmert er sich um das Zumischen von Chemikalien für Flächendesinfektionsmittel.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s15",
    height: sectionDefaultHeight,
    image: image13,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>Alisa Scetinina, 26, Studentin der Kunstakademie Stuttgart</b></Text>
        <Text tall>tritt als Musikerin bei Stuttgart Rooftop Streams auf, einer Initiative die mit Livestreams Künstler, Musiker und Publikum in Kontakt bringt und Spenden für Künstler in Not sammelt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s15_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Im April wollte ich durchstarten. Ich bin Tänzerin und Musikerin. In Lettland und in der Arena Berlin sollte ich als DJane auflegen und einige Tage später im UMBO, einem Musikklub in Zürich, neue Musikstücke präsentieren. Ich hatte mich sehr auf die Auftritte gefreut. Musik machen ist für mich wie verliebt sein und endlich schien es zu laufen.
          <br/><br/>
          2009, mit 15 Jahren, bin ich für meine Ausbildung an der Ballettakademie aus der lettischen Stadt Riga allein nach München gezogen. Drei Jahre später, 2012, unterschrieb ich meinen ersten Vertrag an der Bayerischen Staatsoper. Später wechselte ich zum Stuttgarter Staatsballett. Mittlerweile studiere ich an der Kunstakademie. Um mein Studium zu finanzieren, kellnere ich in Bars und Restaurants und mache elektronische und akustische Livemusik, manchmal auch mit Gesang und Gitarre. Dann kam der Lockdown.
          <br/><br/>
          Mitte März, an einem Donnerstag, musizierte ich mit einem Freund in einer Stuttgarter Bar. Ich habe aufgelegt und er hat live gespielt. Er rief noch ins Mikrofon „Feiert, als sei es die letzte Party.“ Und exakt so war es. Ab Freitag waren alle Diskotheken und Bars geschlossen. Das Restaurant, in dem ich kellnerte, musste dichtmachen. Mein Chef kündigte mir. Selbst die Uni war menschenleer. Ich fühlte mich verloren. Doch dann besuchte ich einen Feinkostladen in meinem Stadtteil, um getrocknete Tomaten zu kaufen – und es ergab sich eine neue Chance.
          <br/><br/>
          Ich kam mit der Verkäuferin ins Gespräch. Sie öffnete mir ihr Herz, berichtete, wie viel Mehrarbeit sie gerade habe: Zum Beispiel die Bestellungen für die Lieferung vorbereiten, das war einfach zu viel. Ich fragte, ob ich helfen könne. Einfach so, ohne Bezahlung. Die Ladenbesitzerin war einverstanden. Nach einigen Tage stellte sie mich in Teilzeit ein. Es macht mich sehr glücklich, nützlich zu sein. Ich liefere zum Beispiel Essen an ältere Leute in der Nachbarschaft und lerne jeden Tag etwas Neues. Meine Chefin umsorgt mich, macht mir kleine Geschenke. Sie ist fast wie eine deutsche Ersatzmutter.
          <br/><br/>
          Im April rief ein Freund an. Er organisierte die „Stuttgarter Roof Top Streams“; Musiksessions mit DJs und Künstlern, die über einen Livestream ins Netz übertragen wurden. Die Zuschauer an den Computern könnten spenden. Ob ich dabei sein wolle. Es hat mich Überwindung gekostet, weil es neu für mich war, aber ja, ich wollte. Auf einem Hausdach startete am 3. Mai in der Dämmerung unser Livestream. Alles war liebevoll dekoriert, ich habe Tribal und Downtempo-Musik aufgelegt und der Krankenpfleger und Künstler Mariano Fernández hat live dazu gemalt. Am Ende applaudierten die Nachbarn auf den umliegenden Balkonen. Später traf sich das gesamte Team zum Abendessen. Zum ersten Mal seit dem Lockdown saß ich mit anderen Menschen am Tisch. Trotzdem fehlte mir etwas: Publikum. Ich will sehen, wie Menschen zu meiner Musik tanzen, muss in ihre Augen schauen, nicht in Kameras. Deshalb blieb es bei einem Livestream.
          <br/><br/>
          Im Juni durften Bars und Restaurants wieder öffnen und kurz danach stand ich wieder am Mischpult. Das Schönste ist: Meine deutsche Mama war auch dabei. Sie brachte ihre Freunde mit und genoss sichtlich den Abend. Sie ist jetzt mein größter Fan.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "sK2a",
    height: "20vh"
  },
  {
    sectionId: "Kapitel-2-Das-Testmobil",
    height: "70vh",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text xl underlined>Kapitel 2</Text>
          <Text xl>Das Testmobil</Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "s17",
    height: sectionDefaultHeight,
    image: image14,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>„Ich habe mich häufig testen lassen, öfter, als es offiziell empfohlen wurde. Einmal schien es mir beim Mittagessen, als könne ich nichts mehr schmecken – meinem Fahrer ging es genauso. Wir testeten uns sofort.“</b></Text>
        <Text>Die Ärztin Verena Werling fuhr drei Monate mit einer mobilen Teststation durch Stuttgart, testete Menschen zuhause.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s18",
    height: sectionDefaultHeight,
    image: image15,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>„Den Geburtstag meines Sohnes im April habe ich verschoben. Er lebt bei seiner Mutter. Da ich mit potenziell Infizierten in Kontakt bin, war es aus Sicherheitsgründen besser, dass er nicht zu mir kommt. Als ich ihn innerhalb der elf Wochen Kontaktverbot einmal sah, hielten wir trotz Mundschutz entsprechend Abstand.“</b></Text>
        <Text>Roland B. ist ehrenamtlicher Rettungssanitäter und stellvertretender Bereitschaftsleiter des Deutschen Roten Kreuzes, Kreisverband Stuttgart. Er war zusammen mit Verena Werling mit dem „Corona-Mobil“ unterwegs.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s19",
    height: sectionDefaultHeight,
    image: image16,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 24. April 2020</Text>
        <Text tall>Verena Werling und Roland B. stehen vor der Tür eines bestätigten Covid-19-Falls, um die Person erneut zu testen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s20",
    height: sectionDefaultHeight,
    image: image17,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>„Es war bedrückend. Mit dem Testmobil sind wir jeden Tag kreuz und quer durch ein menschenleeres Stuttgart gefahren, wenige Autos waren unterwegs. Die Stadt wirkte fremd, ich erkannte sie kaum wieder.“</b></Text>
        <Text>Sagt Verena Werling.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s21",
    height: sectionDefaultHeight,
    image: image18,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 23. Mai 2020 </Text>
        <Text tall>Neben ihren Besuchen bei Verdachtsfällen in Privatwohnungen testen Verena Werling und ihre Kollegen die Bewohner von Alten- und Pflegeheimen, wie hier in einem Stuttgarter Seniorenzentrum im Bereich für Demenzerkrankte. Der Mann auf diesem Bild, dessen Angehörige sich den Covid-19-Test gewünscht hatten, lehnt die Untersuchung ab.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s22",
    height: sectionDefaultHeight,
    image: image19,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>Verena Werling, 40, Ärztin</b></Text>
        <Text tall>meldete sich Mitte März freiwillig, um Corona-Verdachtsfälle zu testen. In diesem Protokoll erzählt sie, wie sie mit der Gefahr umging – und wie sie heute auf die Zeit blickt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s22_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Anfang März schickten Kassenärztliche Vereinigung und Ärztekammer täglich Rundmails und fragten nach Freiwilligen. Ich hatte – unfreiwillig – mehr Zeit als die meisten anderen Ärzte: Gerade hatte sich die Aussicht auf eine Praxisübernahme wieder zerschlagen. Mit Schutzanzug, Maske und Brille würde ich gut geschützt sein. Als dann Ärzte gesucht wurden, die bereit waren zu den ersten Verdachtsfällen nach Hause zu fahren und einen Abstrich zu machen, sprach ich mit meiner Mutter. Bei ihr war kurz zuvor eine Krebserkrankung diagnostiziert und operiert worden. Sie hatte sich etwas erholt und fühlte sich in der Lage, meinen kleinen Sohn zu betreuen, der nun nicht mehr in den Kindergarten konnte. Also meldete ich mich.
          <br/><br/>
          Die erste Schicht mit dem „Corona-Mobil“ begann an einem Dienstag, ‪dem 17. März. Mein Fahrer vom Roten Kreuz und ich kamen täglich in etwa 20 Wohnungen. Manchmal trafen wir alleinlebende Menschen, manchmal ganze Familien, alt und jung, arm und reich. Meldete das Labor ein positives Ergebnis, mussten wir nach ein paar Tagen erneut hinfahren und testen.
          <br/><br/>
          Gerade in der ersten Zeit sahen wir viele Menschen, die sehr krank waren. Ganz junge, trainierte Skifahrer, die zur Tür gekrabbelt kamen, und denen anzumerken war, dass es ihnen richtig schlecht ging. Auch mir wurde es eng in der Brust beim Gedanken daran, was werden würde, wenn ich mich selbst anstecke. Oder jemand aus meiner Familie.‬
          <br/><br/>
          Es war bedrückend. Mit dem Testmobil sind wir jeden Tag kreuz und quer durch ein menschenleeres Stuttgart gefahren, wenige Autos waren unterwegs. Die Stadt wirkte fremd, ich erkannte sie kaum wieder. In dieser Zeit habe ich mich häufig testen lassen, habe nicht auf offizielle Empfehlungen gewartet. Einmal schien es mir beim Mittagessen, als könne ich nichts mehr schmecken. Meinem Fahrer ging es genauso. Wir testeten uns sofort.
          <br/><br/>
          Dann kam eine Zeit, in der wir ganze Einrichtungen „abstreichen“ mussten: Seniorenzentren, Pflegeheime, Hotels, wo potenziell angesteckte Menschen untergebracht waren. Da war ich froh über jeden Moment, in dem ich mich kurz erholen konnte. Das zeigt das Foto von mir aus dem Altenheim.
          <br/><br/>
          Manchmal träume ich davon, dass ich mal wieder in einem Club bin und der Bass wummert mir durchs Herz. Dass ich im Konzert sitze, neben mir hustet jemand, und ich denke mir nichts dabei. Ich fürchte, bis es soweit ist, wird es noch lange dauern.
          <br/><br/>
          Meine letzte Testfahrt war am 12. Juni. Danach trat ich eine Stelle in einer Praxis an. Ich nehme einen neuen Anlauf, um meine eigene Hausarztpraxis zu planen und sorge mich weiter um die Gesundheit meiner Mutter. Ich rechne aber ständig damit, dass es wieder losgeht und ich einspringen muss.
          <br/><br/>
          2020 schien anfangs das schlimmste Jahr in meinem Leben zu werden und ist doch auch schön geworden. Immer hatte ich gefürchtet, dass ich es nicht schaffen würde, meinem kleinen Sohn mal das Fahrradfahren beizubringen. Doch das hat er in diesem Frühjahr mit meiner Hilfe gelernt.‬‬"
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s23a",
    height: "20vh"
  },
  {
    sectionId: "s23",
    height: sectionDefaultHeight,
    image: image21,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 23. Mai 2020</Text>
        <Text tall>Verena Werling nimmt einen Abstrich. Behutsam hat sie die demente Frau zuvor auf den Test vorbereitet.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s24",
    height: sectionDefaultHeight,
    image: image22,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall>Nicht immer finden die Ärztin und ihr Kollege die zu testende Person sofort. In einem Mietshaus in Stuttgart suchen sie eine Anwohnerin.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s25",
    height: sectionDefaultHeight,
    image: image23,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Karlsruhe, 13. Juni 2020</Text>
        <Text tall><b>„Es war trotz allem eine sehr schöne Zeit in meinem Leben.“</b></Text>
        <Text>Erinnert sich Verena Werlings Mutter (Mitte) an die Zeit des Lockdowns. Sie genoss es, viel Zeit mit ihrem Enkelsohn zu verbringen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "Kapitel-3-Die-Pflege",
    height: "70vh",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text xl underlined>Kapitel 3</Text>
          <Text xl>Die Pflege</Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "s27",
    height: sectionDefaultHeight,
    image: image24,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 31. März 2020</Text>
        <Text tall>Visite auf der Covid-19-Station des Marienhospitals. Ein Patient mit Verdacht auf das Virus muss für eine Untersuchung sein Zimmer verlassen. Pfleger Amin Quasem (rechts) hilft ihm, sich Schutzkleidung anzuziehen. Besonders heikel: Der Patient lebt in einem Wohnheim, zusammen mit Risikopatienten. Sein Corona-Test fällt am Ende negativ aus.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s28",
    height: sectionDefaultHeight,
    image: image25,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>„Obwohl wir am Frühstückstisch mit mehr Abstand zueinander sitzen, rücken wir als Team näher zusammen.“</b></Text>
        <Text>Amin Quasem ist Medizinstudent und arbeitet als Gesundheits- und Krankenpfleger am Marienhospital.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s29",
    height: sectionDefaultHeight,
    image: image26,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 31. März 2020</Text>
        <Text tall>Um die Lunge zu entlasten, drehen die Gesundheits- und Krankenpflegerin Ivett Bodo (Mitte) und zwei Kollegen einen Patienten auf den Bauch. Er  hat einen schweren Verlauf von Covid-19, ist Ende 50, adipös und hat hohen Blutdruck.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s30",
    height: sectionDefaultHeight,
    image: image27,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 2. April 2020</Text>
        <Text tall>Amin Quasem am Bett einer älteren Frau, die positiv auf Covid-19 getestet wurde. Er telefoniert mit einer Freundin der Patientin, die sich fast täglich nach ihrem Zustand erkundigt. Die Patientin ist zu müde für ein Gespräch. Etwa zehn Tage später geht es ihr besser und sie kann nach negativem Corona-Test auf eine andere Station des Marienhospitals verlegt werden.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s31",
    height: sectionDefaultHeight,
    image: image28,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 2. April 2020</Text>
        <Text tall>Das Team der Covid-19-Station bereitet sich auf die Frühschicht vor.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s32",
    height: sectionDefaultHeight,
    image: image29,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 6. April 2020</Text>
        <Text tall>Der Pfleger Janni Iakowidis gibt einer 80-jährigen Patientin ihre Medikamente. Kurz zuvor wurde sie positiv auf Covid-19 getestet. Wo sie sich angesteckt hat, kann die Patientin nicht zurückverfolgen. Sie hat Brustkrebs und macht gerade eine Chemotherapie.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s33",
    height: sectionDefaultHeight,
    image: image30,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>Janni Iakowidis, 40, Gesundheits- und Krankenpfleger am Marienhospital</b></Text>
        <Text tall>half Anfang März, die Corona-Quarantänestation im Marienhospital zu eröffnen. Im Protokoll erzählt er, wie dort die ersten Tage mit dem Virus abliefen – und wie er in die Zukunft schaut.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s33_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Als ich im Januar das erste Mal von Covid-19 hörte, habe ich mir keine großen Sorgen gemacht. Ich dachte, das wird wahrscheinlich so wie bei der Schweinegrippe vor zehn Jahren: viel Aufregung um eine Handvoll Fälle.
          <br/><br/>
          Anfang März saß ich im Krankenhaus als Hygienebeauftragter der Pflege und Leiter der neuen Quarantäne-Station in den ersten Sitzungen mit unserer Geschäftsführung und den Abteilungsleitern. Es ging darum, was in Deutschland auf uns zukommen könnte und wie wir uns vorbereiten. Die für Corona eingerichtete Quarantänestation war kaum fertig, da ging es in Stuttgart richtig los.
          <br/><br/>
          Schon am ersten Tag, das war Montag der 9. März, standen dort unten gleich morgens 20, 30 Leute. Die Notaufnahme war sofort überfüllt. Wir haben im Eiltempo Lesegeräte für Krankenkassenkarten bei uns in der Station im zweiten Stock angeschlossen, damit ich Patienten aufnehmen konnte. Die erste Schicht ging zwölf Stunden, in denen wir rund 100 Menschen gesichtet und getestet haben. Außerdem nahmen wir über 500 Anrufe entgegen.
          <br/><br/>
          Auch die folgenden Tage waren krass: Die Leute standen in Menschentrauben vor der Notaufnahme und hatten Panik. In der Dimension war das für uns Neuland. Ich habe in der ersten Woche sicher 20 Überstunden angesammelt.
          <br/><br/>
          Am Anfang wurde jeder getestet, der kam. Doch wir merkten schnell, dass wir nicht ausreichend Personal und Materialien hatten. Die Leute haben am Telefon vor Angst geweint, aber wir hatten nicht genug Tests für Menschen, die nicht gerade in einem Risikogebiet waren. Teilweise kamen Patienten mehrfach am Tag, weil sie hofften, dass ein anderer Arzt sie vielleicht trotzdem testen würde. Das ging so weit, dass wir Sicherheitspersonal einstellen mussten, um die Menschen unter Kontrolle zu halten. Dabei hatten sich die wenigsten infiziert, am ersten Tag waren es vielleicht drei oder vier.
          <br/><br/>
          Dass ich mich selbst anstecke, war täglich Thema. Meine Frau hatte Angst, dass ich das Virus nach Hause zu den Kindern und den Großeltern schleppen würde. Am dritten Tag der Stationseröffnung bekam eine Ärztin Fieber, ich am vierten Tag – da ist mir schon die Muffe gegangen. Wir wurden dann beide negativ getestet, wir waren wahrscheinlich einfach überarbeitet.
          <br/><br/>
          Auffällig war, dass während meiner Schichten noch nie so viele meiner Patienten in so kurzer Zeit gestorben sind wie auf dieser Station. Manche der älteren Menschen bekamen von einem Tag auf den anderen plötzlich überhaupt keine Luft mehr. Die bleiben einem zum Teil auch in Erinnerung, so wie die Dame auf dem Foto.
          <br/><br/>
          In der gleichen Zeit konnten viele meiner Freunde kaum glauben, dass das Virus echt und so gefährlich ist. Anhand ihrer Fragen habe ich schon rausgehört, dass sie Zweifel haben. Man riecht es nicht, man sieht es nicht, darum nehmen es viele nicht ernst.
          <br/><br/>
          Was mich wirklich sauer gemacht hat, waren Bemerkungen darüber, dass Covid-19 fast nur für vorerkrankte Menschen tödlich endet. Es hat mich schockiert, dass manche so auf das Leben anderer pfeifen. Natürlich ist das mit den Vorerkrankungen richtig, aber hat deren Leben deshalb keinen Wert? Darum habe ich in meinem Freundes- und Bekanntenkreis auch einige Kontakte abgebrochen.
          <br/><br/>
          Ende Juli wurde ich von der Quarantänestation auf meine normale Station zurückverlegt. Wenn’s jetzt wieder losgeht, wäre ich aber wieder der Erste, der sich freiwillig meldet. Das habe ich bei der Bundeswehr gelernt: Führen durch Vorbild. Ich kann nichts von meinen Mitarbeitern erwarten, was ich nicht selbst bereit bin zu tun.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s34a",
    height: "20vh"
  },
  {
    sectionId: "s34",
    height: sectionDefaultHeight,
    image: image31,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 12. April 2020</Text>
        <Text tall>Während seiner Nachschicht zwischen Ostersonntag und Ostermontag macht Janni Iakowidis eine Zigarettenpause. Für die Feiertage wurde eine Welle schwerer Covid-19-Verläufe erwartet. Diese blieb aus. Im Normalbetrieb sind bis zu 34 Patienten auf der Station. In dieser Nacht sind es nur zehn.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s35",
    height: sectionDefaultHeight,
    image: image32,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Bei einer von ihnen, der 80-jährigen Patientin mit Brustkrebs und schwerem Covid-19-Verlauf, fühlt Krankenpfleger Janni Iakowidis bei einem Kontrollgang keinen Puls mehr. Die Frau ist gerade verstorben. Eine Woche zuvor war sie noch ansprechbar, dann verschlechterte sich ihr Zustand. Auf ihren Wunsch, gemäß ihrer Patientenverfügung, wurde sie zuletzt nur noch palliativ behandelt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s36",
    height: sectionDefaultHeight,
    image: image33,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 17. April 2020</Text>
        <Text tall>Die Frühschicht der Covid-19-Station bei der Übergabe.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s37",
    height: sectionDefaultHeight,
    image: image34,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined tall><b>„Weil wir so gut besetzt waren, ging es mir während der Zeit auf der Corona-Station ziemlich gut. Das klingt komisch, aber sonst kommt es nie vor, dass ich nur vier Patienten gleichzeitig betreue. Normalerweise sind es zehn.“</b></Text>
        <Text>Svenja Luik ist Krankenpflegerin im Marienhospital. 2019 hatte sie eine Erschöpfungsdepression. Nach einer dreimonatigen Auszeit entschied sie sich, weiter in der Pflege zu arbeiten.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s38",
    height: sectionDefaultHeight,
    image: image35,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 17. April 2020</Text>
        <Text tall>Der Krankenpfleger Amin Quasem kämmt einer älteren Dame die Haare. Sie ist positiv auf das neue Virus getestet worden, ist sehr schwach und kann sich nicht mitteilen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s39",
    height: sectionDefaultHeight,
    image: image37,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>Beatrice Freisinger, 40, Pflegebereichsleiterin</b></Text>
        <Text tall>ist am Marienhospital normalerweise für die Privatstation 9 ab verantwortlich. Zu Beginn der Pandemie wurde diese in die Covid-19-Station umgewandelt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s40",
    height: "80vh",
    image: image36,
    content:
      <OuterTextContainer left><TextContainer>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s40_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Alle meine Mitarbeiter waren in den vergangenen Tagen auf Covid-19 getestet worden, bis zu diesem Zeitpunkt waren alle negativ, es standen nur noch wenige Ergebnisse aus. Dann kam dieser Anruf. Die Kollegin sagte, ihr Test sei positiv. „Bist Du sicher?“, fragte ich sie. Es war der erste Fall auf meiner Station.
          <br/><br/>
          Doch damals wusste man noch nicht, wie sich die Pandemie in Deutschland entwickeln wird. Kommt der große Knall? Die Unsicherheit war groß. Wenn ich dieses Foto sehe, fühle ich zugleich die Anspannung dieser Wochen.
          <br/><br/>
          Auch ich habe mich angesichts der schrecklichen Bilder aus Italien gefragt, wie schlimm es bei uns wird.
          Ich habe Verantwortung für mehr als 25 Mitarbeiter auf dieser Station, ich möchte nicht unsicher wirken, sondern will unterstützen und ermutigen. Als unsere Station in eine Corona-Station umgewandelt wurde, habe ich mich auch emotional bewusst dafür entschieden, sie weiter durch diese besondere Situation zu leiten, weil ich darin auch eine Chance sah. Mein christlicher Glaube hat mir dabei geholfen. Ich beschloss, mich nicht von der Angst beherrschen zu lassen, sondern darauf zu vertrauen, dass wir diese Herausforderung schaffen.
          <br/><br/>
          Eine der wichtigsten Fragen dieser Wochen war, wie weit unsere Schutzausrüstung reicht. Früher haben wir jedes Mal, wenn wir ein Krankenzimmer betreten haben, einen neuen Mundschutz verwendet. Nun mussten wir mit einer Maske pro Schicht auskommen.
          <br/><br/>
          Kurz nachdem die Kanzlerin bei einer Pressekonferenz verkündet hatte: „Es ist ernst“, standen mehr als hundert Menschen draußen vor dem Eingang zu unserer Notaufnahme, darunter viele junge Leute. Sie wollten sich testen lassen, manche wirkten richtig panisch. Wir konnten nur die testen, die aus Risikogebieten kamen und die Symptome hatten. Die meisten waren negativ.
          <br/><br/>
          Die Anzahl der Patienten auf den beiden Covid-Normalstationen änderte sich manchmal stündlich, denn zu uns kamen alle Verdachtsfälle. Waren sie negativ getestet, wurden sie auf andere Stationen verlegt und wir bekamen neue Verdachtsfälle. Bestätigte Fälle blieben bei uns oder wurden auf die Intensivstation verlegt. Unsere erste Covid-19-Patientin war eine 98 Jahre alte Dame. Sie erklärte uns, sie hat einen Krieg überlebt, sie wird auch das hier überleben. Sie behielt recht, sie konnte bald entlassen werden.
          <br/><br/>
          Die Pflege war schon vor Corona am Limit. Trotzdem waren viele bereit, Dienste zu tauschen, sogar ihren lang geplanten Urlaub zu verschieben. Wir bekamen auch von anderen Stationen viel Unterstützung, alle waren hilfsbereit. Wir hatten pro Schicht eine zusätzliche Pflegekraft, bei der Hälfte der Patienten. Unser Nachtdienst war doppelt besetzt. Normalerweise ist auf unserer Station eine examinierte Pflegekraft nachts allein mit 34 Patienten.
          <br/><br/>
          Wenn wir auch nach der Pandemie solche Personalstärken hätten, dann wäre unser Job attraktiver. Wir wären nicht permanent am Limit, müssten nicht durcharbeiten, wir könnten sogar regelmäßig Pausen machen. Bisher reichte die Zeit teilweise nicht mal für den Gang auf die Toilette.
          <br/><br/>
          Wir könnten öfter mit einem guten Gefühl nach Hause gehen, ohne schlechtes Gewissen, weil jemand vielleicht mehr Zuwendung gebraucht hätte.
          <br/><br/>
          Ich hatte in den vergangenen Monaten so viele gute Begegnungen mit Kollegen und Patienten, ich habe so viel Wertschätzung erlebt wie in zwanzig Jahren nicht.
          <br/><br/>
          Die Pflege hat nicht nur an Ansehen in der Gesellschaft gewonnen, sie wird auch immer in Verbindung mit dieser Krise gebracht werden. Ich hoffe, die Politik erkennt, dass ohne uns im Gesundheitssystem nichts mehr gelaufen wäre. Es darf aber nicht nur bei Worten bleiben, die Arbeitsbedingungen müssen sich ändern, nicht nur zum Wohl der Pflegekräfte, sondern auch für unsere Patienten.
          <br/><br/>
          Anfang Juni wurde unsere Corona-Isolierstation wieder in eine Normalstation umgewandelt und ist es aktuell noch immer.
          <br/><br/>
          Doch seit Anfang Oktober steigen die Infektionszahlen wieder, und damit auch die Anspannung in der Gesellschaft. Ich finde es wichtig, dass wir verantwortungsbewusst handeln, aber nicht in Panik verfallen. Wenn ich Kontakt hatte mit Menschen, die positiv waren, mache ich einen Test und warte den zweiten Test ab. Ich sage auch mal eine Verabredung am Wochenende ab, weil ich nichts riskieren will.
          <br/><br/>
          Es war nicht alles negativ in diesem Jahr, im Gegenteil: Manches zeigte sich klarer. Natürlich gab es auch Patienten, die gereizt reagierten, wenn ihr Test positiv war und sie in Quarantäne mussten, aber der Großteil war verständnisvoll. Ich hatte viele gute Begegnungen mit Mitarbeitern und Patienten. Mein Eindruck war, dass man sich in dieser Zeit existenzieller Ängste auf das Wesentliche konzentrierte. Und das war das gute menschliche Miteinander.
          Wenn es doch so bliebe!“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s41a",
    height: "20vh"
  },
  {
    sectionId: "s41",
    height: sectionDefaultHeight,
    image: image38,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 17. April 2020</Text>
        <Text tall>Janni Iakowidis wird auf das neue Corona-Virus getestet. Die Mitarbeiter des Marienhospitals müssen die Untersuchung alle zwei Wochen über sich ergehen lassen. Anfang Oktober hat Iakowidis 13 negative Testergebnisse.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s42",
    height: sectionDefaultHeight,
    image: image39,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 23. April 2020</Text>
        <Text tall>Die Physiotherapeutinnen Corinna Körbler und Nadja Beer (rechts) kümmern sich um eine Patientin mit asymptomatischem Covid-19-Verlauf. Die Patientin hatte einen Schlaganfall und sollte eigentlich in eine Reha. Routinemäßig wurde sie auf das Virus getestet. Die Frau ist verwirrt, weiß überhaupt nicht, warum sie hier ist. Sie sei doch nicht krank, sagt sie.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s43",
    height: sectionDefaultHeight,
    image: image40,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Nachdem sie mit ihr ein paar Schritte zum Fenster gelaufen ist, verabschiedet sich Nadja Beer von einer älteren Patientin. Für viele auf der Covid-19-Station sind der Besuch der Physiotherapeutinnen und die gemeinsamen Übungen eine willkommene Abwechslung. Die Patienten dürfen ihr Zimmer nicht verlassen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s44",
    height: sectionDefaultHeight,
    image: image41,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>„Corona zeigt einem, dass wir jeden Moment miteinander genießen müssen. Ich gehe mit niemandem mehr im Streit auseinander.“</b></Text>
        <Text>Nadja Beer hat gerade erst ihre Ausbildung zur Physiotherapeutin abgeschlossen. Sie meldete sich freiwillig für den Dienst auf der Covid-19-Station.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s45",
    height: sectionDefaultHeight,
    image: image42,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 8. Mai 2020</Text>
        <Text tall>Der vorerst letzte Tag als Covid-19-Station. Die Isolierstation wird wieder zur Normalstation umgebaut. Wegen der Infektionsgefahr werden die Vorhänge in allen Zimmern ausgetauscht.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s46",
    height: sectionDefaultHeight,
    image: image43,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Während Beatrice Freisinger und das gesamte Team der Station 9 ab ihr negatives Testergebnis mitgeteilt bekommen...</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s47",
    height: sectionDefaultHeight,
    image: image44,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall>...werden mehrere Pflegekräfte einer anderen Station positiv auf Covid-19 getestet. Alle zwanzig Patienten werden auf die 9 ab verlegt. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s48",
    height: sectionDefaultHeight,
    image: image45,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall>Auf der Inneren Intensivstation drehen Gesundheits- und Krankenpflegerin Ivett Bodo, ihre Kollegin und zwei Ärzte eine Patientin auf den Bauch.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s49",
    height: sectionDefaultHeight,
    image: image46,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>Ivett Bodo, 27, Gesundheits- und Krankenpflegerin</b></Text>
        <Text tall>arbeitet auf der Inneren Intensivstation des Stuttgarter Marienhospitals und berichtet in diesem Protokoll von den Herausforderungen bei der Behandlung und Pflege von Covid-19 Patienten.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s49_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Der Patientin ging es von Tag zu Tag schlechter. Sie war erst 53, litt aber seit längerem an einer entzündlichen Erkrankung der Gefäße, hinzu kam eine Depression, und nun auch noch Covid-19.
          <br/><br/>
          An diesem Vormittag mussten wir sie ins künstliche Koma versetzen und intubieren. Wenige Stunden später war es schon nötig, sie in Bauchlage zu drehen, um die Lunge zu entlasten. Diese Lagerung kannte ich schon von anderen Lungenkrankheiten, sie ist Ultima Ratio, wenn sonst nichts mehr hilft. Denn die Drehung eines beatmeten Patienten mitsamt allen Zugängen und Schläuchen ist eine aufwändige Prozedur, die akribische Vorbereitung verlangt.
          <br/><br/>
          Es wäre verheerend, wenn beispielsweise der Beatmungsschlauch verrutscht und nicht mehr richtig in der Lunge läge, das ließe sich in der Bauchlage kaum noch korrigieren.
          <br/><br/>
          Wir arbeiten deshalb selbst bei einer so zierlichen Patientin im Vierer-Team. Der Stationsarzt wachte am Kopfende darüber, dass der Tubus an seinem Platz blieb, er gab uns Kommandos. Das eigentliche Drehen musste schnell gehen.
          <br/><br/>
          Wir lagerten unsere Patientin auf zusammengerollte Decken, die wir unter ihre Hüfte und Schulter legten. Damit sie keine Druckstellen im Gesicht bekommt, betteten wir das Gesicht auf einen Schaumstoffkranz.
          <br/><br/>
          Das Drehen dauert in der Regel weniger als eine Minute, die gesamte Vor- und Nachbereitung allerdings mehr als eine Stunde, die Prozedur muss etwa alle 18 Stunden wiederholt werden, dann wird die Patientin auf den Rücken gedreht.
          <br/><br/>
          Irgendwann hat man damit Routine. Neu und gänzlich ungewohnt war hingegen, dass die Beatmung der Covid-19-Patienten erstaunlich hohe Drücke der Beatmungsmaschine verlangte, um die Lungenbläschen offen zu halten und mit Sauerstoff zu versorgen.
          <br/><br/>
          Medizinisch und pflegerisch ist dieses neue Krankheitsbild sehr spannend. Es gab uns immer neue Rätsel auf, dazu gehörte auch die starke Sedierung mit Schmerz- und Schlafmitteln, die notwendig ist, um die Patienten im Koma zu halten. Bisher ist ungeklärt, warum das so ist.
          <br/><br/>
          Es gibt auch sonst immer noch vieles, was wir nicht wissen über Covid-19. Wir mussten mit den Möglichkeiten zurechtkommen, die wir hatten, mussten ausprobieren. Und aushalten, dass in manchen Fällen nichts mehr half.
          <br/><br/>
          Die Patientin starb Anfang Juni an multiplem Organversagen.
          <br/><br/>
          Covid-19 hat uns täglich an Grenzen gebracht. Beispielsweise, wenn wir unseren Patienten kurz nach dem Aufwachen erklären mussten, dass sie keinen Besuch bekommen dürfen. Besonders schwer verständlich ist das für Patienten, die noch im Delir sind und nicht verstehen, was mit ihnen geschieht.
          <br/><br/>
          Wir Pflegekräfte wurden zu den primären Ansprechpersonen, wir verbrachten jeden Tag mehrere Stunden an ihrem Bett, aber natürlich konnten wir die Angehörigen nicht ersetzen, zumal wir, vermummt mit Schutzbrille, Maske und Handschuhen, auf manchen anfangs befremdlich wirkten. Ein Patient bat mich, die Maske abzunehmen. Die Tür des Isolierzimmers hat ein Fenster. Ich klopfte von außen gegen die Scheibe und nahm kurz die Maske ab, damit er mein Gesicht sehen konnte. Er nickte und sah zufrieden aus.
          <br/><br/>
          Für die kommenden Monate wünsche ich mir, dass sich die Menschen weiter an die Regeln halten. Dass sie einen Mund-Nasenschutz tragen, auf Abstand achten. Dass sie respektvoll miteinander umgehen und Verständnis füreinander zeigen. Wir können uns alle glücklich schätzen, dass wir nicht erleben, was unsere Patienten erleben mussten.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s50a",
    height: "20vh"
  },
  {
    sectionId: "s50",
    height: sectionDefaultHeight,
    image: image47,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 21 April 2020</Text>
        <Text tall>Ivett Bodo hilft ihrem Kollegen während der Nachtschicht bei der Versorgung eines Patienten.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s51",
    height: sectionDefaultHeight,
    image: image48,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 14 Mai 2020</Text>
        <Text tall>Ivett Bodo dreht einen Patienten Richtung Fenster und schaltet das Radio an. Durch Musik sollen Reize gesetzt und der Genesungsprozess beschleunigt werden. Der Patient hat zwar typische Covid-19-Symptome, ist fiebrig und musste intubiert werden. Doch alle Tests auf das Virus fielen negativ aus. Vorsichtshalber wird er trotzdem weiterhin wie ein Patient mit Corona behandelt. Anfang Juni geht es ihm besser und er kann auf die Normalstation verlegt werden.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s52",
    height: sectionDefaultHeight,
    image: image49,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 22. Juni 2020</Text>
        <Text tall>Zurück im Alltag. Die Krankenpflegerin Laura Reppekus ist in ihrer Nachtschicht alleine für 23 Patienten zuständig. Hier holt sie einen Mülleimer für Schutzkleidung aus der Abstellkammer. Sie muss ein Zimmer für einen Patienten mit Magen-Darm-Erkrankung vorbereiten. Auch von diesen Patienten geht ein Infektionsrisiko aus.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s53",
    height: sectionDefaultHeight,
    image: image50,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 23. Juni 2020</Text>
        <Text tall>Die Krankenpflegerin Laura Reppekus (rechts) bei der Übergabe zur Frühschicht. Ihre Nacht verlief ruhig.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "Kapitel-4-Der-schwere-Verlauf",
    height: "70vh",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text xl underlined>Kapitel 4</Text>
          <Text xl>Der schwere Verlauf</Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "s55",
    height: sectionDefaultHeight,
    image: image51,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 31. März 2020</Text>
        <Text tall>Ein Zimmer auf der Covid-19-Intensivstation im Marienhospital in Stuttgart. Der Patient Wenzel D. liegt am Fenster. Wie sein Bettnachbar hat er einen schweren Verlauf von Covid-19 und liegt seit zehn Tagen im künstlichen Koma. Sein Leben hängt an einer Beatmungsmaschine und an den behandelnden Ärzten und Pflegekräften.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s57",
    height: sectionDefaultHeight,
    image: image53,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 28. April 2020</Text>
        <Text tall>Der Oberarzt Michael Heinold hält die Hand von Wenzel D.. Lange war sich der Mediziner nicht sicher, ob sein Patient die Erkrankung überleben wird, er bereitete dessen Frau Mirka D. bereits auf einen würdigen Abschied vor.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s58",
    height: sectionDefaultHeight,
    image: image54,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall>Wenzel D. kommt langsam zu sich. Mit einer Collage aus Bildern von seiner Frau und Tochter untersucht Michael Heinold, ob das Gehirn des Patienten durch das lange Koma Schaden erlitten hat. Glücklicherweise erinnert sich Wenzel D. gut.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s56",
    height: sectionDefaultHeight,
    image: image52,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>Michael Heinold, 50, Oberarzt</b></Text>
        <Text tall>leitet die Internistische Intensivstation am Marienhospital Stuttgart, die im März 2020 zur Covid-19-Intensivstation umgewandelt wurde.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s56_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Wir hatten die Angehörigen gebeten, Familienfotos zu einer Collage zusammenzustellen. Ich wollte damit herausfinden, ob der Patient, der nach akutem Lungenversagen 31 Tage lang im künstlichen Koma gelegen hatte, die Menschen erkennt und Erinnerungen abrufen kann, oder ob sein Gehirn Schaden erlitten hatte. Fotos oder ein selbstgemaltes Bild des Enkelkinds helfen, an Vertrautes anzuknüpfen und das Gehirn zu stimulieren.
          <br/><br/>
          Herr D. konnte sich erfreulicherweise gut erinnern. Er konnte nicht sprechen, weil er noch beatmet wurde, aber er konnte den Kopf schütteln, nicken und mit dem Finger auf die Collage deuten.
          <br/><br/>
          Binnen weniger Tage hatten wir Mitte März die Intensivstation zur Isolierstation für zwölf Patienten mit Beatmungsplätzen umgerüstet und zusätzliches Personal aus dem ganzen Haus geholt.
          <br/><br/>
          Die ersten paar Tage war noch Ruhe, dann ging es Schlag auf Schlag, fast jeden Tag kamen zwei neue Fälle. Wir waren erschrocken, wie krank die Menschen ankamen. Herr D. war einer unserer ersten Patienten. Die Computertomographie seiner Lunge zeigte milchige Einsprengsel, die aussahen wie Pflastersteine. Das waren Überbleibsel sterbender Zellen, in denen sich die Viren eingenistet hatten.
          <br/><br/>
          Es gab keine Therapie gegen Covid-19. Das war auch für uns Ärzte schwer auszuhalten. Wir wussten, wir können nur die Symptome kontrollieren, aber nicht den Feind bekämpfen. Wir hatten uns bewusst gegen Experimente entschieden, gegen Medikamente, von denen wir nicht überzeugt waren. Denn wir wollten die Patienten keinem weiteren Risiko aussetzen. Ansonsten konnten wir nur jeden Tag hoffen, dass die Zeit heilt. Mehr nicht.
          <br/><br/>
          Bei Herrn D. hofften wir, dass sein Organismus selbst mit dem Virus fertig wird, während die Beatmungsmaschine so lange die Funktion der Lunge übernimmt, bis er wieder selbst atmen kann.
          <br/><br/>
          Er hatte keine relevanten Vorerkrankungen. Und die einzelnen Risikofaktoren, das zeigte sich bald auch in anderen Fällen, waren auch nicht immer entscheidend für den Verlauf. Einige Patienten mit schweren Vorerkrankungen erholten sich, während Patienten ohne Vorerkrankung verstorben sind.
          <br/><br/>
          Inzwischen gibt es Medikamente, die Anlass zur Hoffnung geben. Kortison kann bei schweren Verläufen die Überlebenschancen verbessern.
          <br/><br/>
          Wir wissen allerdings wenig über die Spätfolgen und ob diejenigen, die überlebten, ihre alte Leistungsfähigkeit wiedererlangen. Das Virus hinterlässt Narben auf der Lunge, es schädigt die Muskulatur und das Nervensystem.
          <br/><br/>
          Die Pandemie hat uns gezeigt, dass wir als Gesellschaft in der Lage sind, Notwendigkeiten zu erkennen, die auf wissenschaftlicher Evidenz beruhen. Die Politik muss sie machbar und sinnvoll umsetzen. Nur so kann man große Herausforderungen angehen. Auch beim Klimawandel werden wir nur etwas verändern, wenn wir bereit sind, uns einzuschränken. Denn echte Probleme gehen nicht weg, indem man sie verdrängt.
          <br/><br/>
          Als Deutschland im März die Rollläden herunterließ, hatte das wenige Wochen später unmittelbare Auswirkung auf unsere Arbeit. Zunächst kamen keine neuen Patienten mehr mit schweren Covid-19-Pneumonien. Das ist vermutlich einer der Gründe, warum bei uns im Vergleich zu Italien so wenige gestorben sind. Wir hatten in dieser kritischen Phase Zeit und Platz, uns um schwere Fälle wie Herrn D. zu kümmern. Sonst hätte er nicht überlebt.
          <br/><br/>
          Nicht nur in unserer Klinik zogen alle an einem Strang. Es trafen sich auch die Intensivmediziner der Stuttgarter Kliniken und der Uniklinik Tübingen regelmäßig zur Telefonkonferenz, um über unsere Fälle zu sprechen und Erfahrungen auszutauschen. Das gab es noch nie, schließlich konkurrieren Kliniken gewöhnlich. Keiner versuchte, Wissen zu horten, die Zusammenarbeit war toll. Dies zeigt: Wenn es drauf ankommt, kann sich unser System auf seine Aufgaben besinnen.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s59a",
    height: "20vh"
  },
  {
    sectionId: "s59",
    height: sectionDefaultHeight,
    image: image55,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 8. Mai 2020</Text>
        <Text tall>Schon seit Wochen stehen Katerina D. und Mirka D. täglich vor dem Marienhospital und schauen zu dem Zimmerfenster ihres Vaters und Ehemannes. An diesem Tag ist Wenzel D. zum ersten Mal fit genug, um zurückzuschauen – auf einem Stuhl sitzend. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s60",
    height: sectionDefaultHeight,
    image: image56,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 14. Mai 2020</Text>
        <Text tall>Vor drei Tagen wurde Wenzel D. in eine Lungenklinik nach Bad Cannstatt verlegt. Ehefrau und Tochter sitzen in der Straßenbahn, um ihn zu besuchen. Am Ende darf nur Mirka in die Klink. Katerina ist frustriert. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s61",
    height: sectionDefaultHeight,
    image: image57,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Bei einem ihrer Besuche macht Mirka D. dieses Foto von ihrem Ehemann. Sein Zustand verbessert sich langsam. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s62",
    height: sectionDefaultHeight,
    image: image58,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 15. Mai 2020</Text>
        <Text tall><b>„Überall sind Polizeiwagen und sichern die Großdemo gegen die Hygienemaßnahmen ab. Ich verstehe die Demo nicht. Mein Vater liegt keine 500 Meter entfernt und ist zu schwach, den Alu-Deckel von seinem Joghurt abzureißen. Ich brauche keine Verschwörungstheorien, die Corona-Krise ist für uns ganz real.“</b></Text>
        <Text>Das schreibt Katerina D. in ihr Tagebuch. Sie sitzt in ihrem alten Kinderzimmer.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s63",
    height: sectionDefaultHeight,
    image: image59,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Stuttgart, 15. Mai 2020</Text>
        <Text tall>„Er fängt schon wieder an zu schimpfen“, freut sich Katerina D., als sich ihr Vater über die Situation im Krankenhaus beschwert. So lange im Bett zu liegen, ist der Rentner nicht gewöhnt. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s64",
    height: sectionDefaultHeight,
    image: image60,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined><b>Wenzel D., 68, Covid-19-Patient</b></Text>
        <Text tall>wurde nach seinem Aufenthalt im Marienhospital und der Lungenklinik in die „Klinik für Geriatrische Rehabilitation“ des Klinikums Christophsbad in Göppingen verlegt. In der Reha fand er langsam zurück in den Alltag. Hier an seiner Seite: Tochter Katerina.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s64_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          <b>Wenzel D.:</b>
          <br/><br/>
          „Meine Tochter Katerina hatte gerade Treppensteigen mit mir geübt. Seit mehr als zehn Wochen war ich nun schon in Krankenhäusern, zuletzt in der Rehaklinik in Göppingen. An die ersten vier Wochen im Marienhospital in Stuttgart kann ich mich nicht erinnern, ich lag im Koma und hing an der Beatmungsmaschine. Nun ging es endlich aufwärts, wenn auch sehr langsam. Zehn Stufen! Ich war stolz.“
          <br/><br/>
          <b>Katerina D.:</b>
          <br/><br/>
          „Er musste sich am Geländer und an meinem Arm festhalten, er war noch sehr wacklig. Mein Vater hat erst nach und nach verstanden, wie krank er ist. Er dachte, nach der Reha kann er sofort alles wieder machen wie zuvor. Aber er war noch sehr schwach, anfangs saß er im Rollstuhl, dann brauchte er den Rollator.“
          <br/><br/>
          <b>Wenzel D.:</b>
          <br/><br/>
          „Ich musste das Atmen wieder lernen. Manchmal fragte ich meine Frau, wie atmest du jetzt gerade? Meist atmete ich zu schnell, dann musste ich mich hinsetzen und langsamer atmen, in regelmäßigem Rhythmus. Seit meiner Entlassung aus der Rehaklinik im Juli versuche ich, allein voranzukommen. Mein Pulsometer am Finger misst meist eine Sauerstoffsättigung von 93 Prozent, gesunde Leute haben 98 Prozent. Mein Hausarzt sagt, das sei ein guter Wert, aber ich fühle mich nicht gut.
          <br/><br/>
          Meine Lunge tut nicht weh, aber sie ist sehr geschädigt. Meine Finger fühlen sich steif an und schmerzen, wenn ich etwas anfasse. Ich habe 16 Kilo Gewicht verloren, sehr viel Muskulatur, und immer noch halb so viel Kraft wie vor meiner Erkrankung. Jeder Schritt strengt an. Ohne das Sauerstoffgerät, das ich in einer schwarzen Tasche mit mir herumtrage, geht nichts. Ich bin ungeduldig. Ich würde gern noch eine Reha machen, um mehr Kraft zu bekommen, ich brauche Unterstützung, damit ich schneller vorankomme. Zuhause sitzen genügt mir nicht.“
          <br/><br/>
          <b>Katerina D.:</b>
          <br/><br/>
          „Ich bin froh, dass meine Eltern nun wieder selbst zurechtkommen. Am Anfang war es für meine Mutter eine große psychische Belastung. Sie konnte nicht alleine sein, deshalb war ich so lange bei ihr. Der schlimmste Moment war, als der Arzt uns nach vier Wochen sagte, wenn es jetzt nicht besser wird, dann müssten wir uns Gedanken machen über einen würdigen Abschied.“
          <br/><br/>
          <b>Mirka D.:</b>
          <br/><br/>
          „Als mein Mann im Krankenhaus war, zog meine Tochter zu mir. Sein leerer Platz am Tisch, das war hart. Wir wollten, dass er wieder dort sitzt. Das mit dem Platz habe ich ihm nie erzählt. Wir sprechen nicht so viel über emotionale Sachen. Es war doch selbstverständlich, dass wir alles tun, damit es ihm besser geht. Meine Tochter war eine enorme Stütze. Wenn ich anfing zu zweifeln, sagte sie, er schafft es!
          <br/><br/>
          Nun kommt er nachts ohne Sauerstoffgerät aus, auch wenn er still sitzt, beispielsweise Fernsehen schaut, geht es ohne. Als wir im September an die Ostsee fuhren, haben wir schöne Spaziergänge gemacht.“
          <br/><br/>
          <b>Katerina D.:</b>
          <br/><br/>
          „Ich war überrascht, wie viel im Urlaub ging, sogar ein wenig Radfahren. Er macht Atemübungen, hat immer den Pulsoximeter dabei, das ist sein bester Freund.
          <br/><br/>
          Er ist zuversichtlich. Aber er hat gemerkt, dass er verletzlich ist. Und dass alles sehr lange braucht. Er kann beispielsweise noch keine schweren Sachen tragen. Er wollte etwas hämmern in der Wohnung und konnte den Hammer nicht halten.
          <br/><br/>
          Wir haben uns gegenseitig Vollmachten ausgestellt. Vorher war das nie ein Thema in der Familie, über das Sterben wollten meine Eltern überhaupt nicht sprechen. Das ist jetzt anders.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s65a",
    height: "20vh"
  },
  {
    sectionId: "s65",
    height: sectionDefaultHeight,
    image: image61,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Göppingen, 12. Juni 2020</Text>
        <Text tall>Die Geschmacksnerven funktionieren wieder. Mirka D. bringt ihrem Mann Schnitzel und Gurken mit in die Reha-Klinik.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s66",
    height: sectionDefaultHeight,
    image: image62,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Göppingen, 17. Juli 2020</Text>
        <Text tall>Atem- und Bewegungsübungen sollen seine Lunge stärken und Muskeln aufbauen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s67",
    height: sectionDefaultHeight,
    image: image63,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 18. Juli 2020</Text>
        <Text tall>Zum ersten Mal darf Wenzel D.  für das Wochenende die Klinik verlassen. Katerina holt ihn ab.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s68",
    height: sectionDefaultHeight,
    image: image64,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Doch nach Hause fahren will er selbst.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s69",
    height: sectionDefaultHeight,
    image: image65,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Zwei Sauerstoffgeräte, ein tragbares und ein stationäres für die Nacht, muss Wenzel D. mitnehmen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s70",
    height: sectionDefaultHeight,
    image: image66,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Nach vier Monaten sitzt das Ehepaar wieder vereint im Wohnzimmer. Auch Mirka D. war Ende März positiv auf Covid-19 getestet worden. Doch sie hatte nur leichte Symptome. Wo sie sich angesteckt haben könnten, wissen die beiden bis heute nicht. Sie waren nicht im Skiurlaub, besuchten keine größeren Veranstaltungen, kein Familienfest, sie waren die meiste Zeit in ihrer Wohnung.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "Kapitel-5-Die-neue-Normalitaet",
    height: "70vh",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text xl underlined>Kapitel 5</Text>
          <Text xl>Die neue Normalität</Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "s72",
    height: sectionDefaultHeight,
    image: image67,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Ellwangen, 2. Mai 2020</Text>
        <Text tall>Geflüchtete lassen sich während ihrer Quarantäne in der Landesaufnahmestelle in Ellwangen Lebensmittel bringen. Sie selbst dürfen die Einrichtung nicht verlassen, nachdem Ende April 406 der rund 600 Bewohner und 32 Mitarbeiter positiv auf Corona getestet wurden.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s73",
    height: sectionDefaultHeight,
    image: image68,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 2. Mai 2020</Text>
        <Text tall>Mehr als 4000 Teilnehmer demonstrieren gegen die Corona-Maßnahmen der Regierung, etwa gegen die Ausgangs- und Kontaktbeschränkungen und das Tragen von Masken und für ihr Grundrecht auf Versammlungsfreiheit. Initiator Michael Ballweg, der auf der Demo auftritt, spricht von einem „Freiheits-Virus“, das in Stuttgart ausgebrochen sei.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s74",
    height: sectionDefaultHeight,
    image: image69,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Tübingen, 6. Mai. 2020 </Text>
        <Text tall>Die Corona-Teststation auf dem Tübinger Festplatz ist menschenleer. An diesem Tag waren für die Stadt drei Neuinfektionen gemeldet worden.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s75",
    height: sectionDefaultHeight,
    image: image70,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Rust, 26. Mai 2020</Text>
        <Text tall>Knapp zwei Monate war der Europa-Park geschlossen. Nun bereiten sich die Mitarbeiter auf die Wiedereröffnung vor und laden die Presse zum Termin ein.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s76",
    height: sectionDefaultHeight,
    image: image71,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Uedem, 10. Juni 2020</Text>
        <Text tall>Die Studentin Hannah Uszball bei der Spargelernte. Bereits seit April arbeitet sie als Erntehelferin. Viele landwirtschaftliche Betriebe waren dieses Jahr auf Menschen angewiesen, die noch nie auf dem Spargelfeld gearbeitet haben. Für den Hof, bei dem Hannah aushilft, arbeiten normalerweise 30 ausländische Erntehelfer. Diese Saison waren es nur sieben. Und zwanzig Studierende als Aushilfskräfte. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s77",
    height: sectionDefaultHeight,
    image: image72,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Titisee, 19. Juni 2020</Text>
        <Text tall>Leere am Touristen-Hotspot. Schon im Februar waren die Folgen des Corona-Virus in der Region Titisee im Hochschwarzwald spürbar. Sie ist vor allem bei Asiaten ein beliebtes Reiseziel.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s78",
    height: sectionDefaultHeight,
    image: image73,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined><b>Antonia Köser, 25, Studentin der Ernährungsmedizin</b></Text>
        <Text tall>hat von April bis Juli ehrenamtlich bei den Schwäbischen Tafeln gearbeitet. Sie sortiert Essen, prüft Berechtigungsscheine, achtet darauf, dass die Menschen genügend Abstand zueinander halten.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s78_protokoll",
    content:
      <OuterTextContainer><TextOnlyContainer>
        <Text tall>
          „Als ich im April bei der Tafel anfing, war alles ziemlich chaotisch. Für die Verantwortlichen war es neu, dass so viele neue Ehrenamtliche da waren. Die regelmäßig helfenden älteren Menschen, die sich mit den Abläufen auskennen, gehörten durch Corona plötzlich zur Risikogruppe und konnten nicht mehr mitarbeiten. Stattdessen kamen viele Studenten, aber auch Menschen in Kurzarbeit. Ich habe mit einigen Berufstätigen gesprochen, die eine Beschäftigung suchten oder im Homeoffice waren, und einen Grund brauchten, morgens aufzustehen. Obwohl sich so viele freiwillig meldeten, waren es doch zu wenige für die Massen an Lebensmitteln, die es gab. Andere Tafeln waren anfangs geschlossen und zusätzlich bekamen wir Lebensmittel aus Branchen, die durch den Lockdown eingeschränkt waren, beispielsweise Croissants von Lufthansa.
          <br/><br/>
          Nach einigen Wochen war alles besser organisiert. Wir wurden zu einem eingespielten Team, wechselten uns mit den Aufgaben ab. Am Eingang der Tafel standen wir meistens allein oder zu zweit und haben die Berechtigungsscheine der Menschen geprüft, die anstanden. Wir haben auch geschaut, ob sie mit dem Familiennamen an dem Tag überhaupt einkaufen durften. Es fiel mir schwer, Menschen wieder nach Hause zu schicken. Damit war ich überfordert. Auch weil ich außerdem drauf achten musste, dass die Kundinnen und Kunden untereinander und zu uns ausreichend Abstand halten.
          <br/><br/>
          Mit der Zeit lernte ich einige Menschen besser kennen und habe mit ihnen gerne ein kleines Pläuschchen gehalten. Mein erster Kontakt zu ihnen war in der Warteschlange, die teilweise um den ganzen Häuserblock reichte. Da wurde mir erstmals bewusst, wie viele Menschen auf die Tafel angewiesen sind und dass sie bis zu zwei Stunden warten müssen. Für viele von ihnen war es vor Corona einfacher zur Tafel zu gehen, weil sie nicht in aller Öffentlichkeit anstehen mussten, sondern im Vorraum warten konnten. Einigen war es unangenehm, dass jeder sie in der Schlange sehen konnte. Bestimmt sind andere gar nicht erst gekommen.
          <br/><br/>
          Lebensmittel der Tafel kosten nur zehn bis dreißig Prozent des Originalpreises. Toll fand ich, dass die Hürde für einen Berechtigungsschein einfach herabgesetzt wurde und zum Beispiel ein Nachweis des Kontostands gereicht hat. Ich habe Einzelschicksale von Menschen mitbekommen, die zum ersten Mal bei der Tafel waren, weil sie durch die Corona-Krise in eine Notlage geraten sind.
          <br/><br/>
          An einem der ersten Tage, an denen ich in der Warteschlange für Ordnung gesorgt habe, kam ein junger Mann und hat gefragt, wie er denn hier einkaufen kann. Er hat mich angesprochen, weil ich durch die gelbe Weste gut zu erkennen war. Er erzählte mir, dass er einfach gar kein Geld mehr hat und nicht wüsste, wie er seine Wohnung bezahlen sollte.
          <br/><br/>
          Ich hatte zuvor wenig Berührungspunkte mit hilfsbedürftigen Menschen, habe aber gemerkt, dass diese Art und Weise zu arbeiten und mit Menschen zusammenzukommen, in meinem Leben bislang viel zu kurz gekommen ist und künftig eine größere Rolle spielen soll.
          <br/><br/>
          Der Teil der Bevölkerung, der Hilfe benötigt, ist durch Corona bestimmt noch größer geworden. Dass dadurch die Kluft zwischen Arm und Reich noch weiter auseinander geht, macht mir Angst. Aber ich habe auch gesehen, dass die Krise vielen Menschen die Augen geöffnet hat, dass sich strukturell auch was ändern muss.
          <br/><br/>
          Und auch sonst verbinde ich mit der Zeit viele schönen Erinnerungen. In meiner Wohngemeinschaft haben wir auf einmal viel mehr Zeit miteinander verbracht. Dabei haben ein Mitbewohner und ich gemerkt, dass wir mehr sind als nur Mitbewohner.“
        </Text>
      </TextOnlyContainer></OuterTextContainer>
  },
  {
    sectionId: "s79a",
    height: "20vh"
  },
  {
    sectionId: "s79",
    height: sectionDefaultHeight,
    image: image74,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Heilbronn, 22. Juni 2020</Text>
        <Text tall><b>„Im Moment verlege ich mein Büro oft nach draußen, Beratung geht jetzt mit der Decke auf der Wiese oder auf einer Bank hinter der Schule.“</b></Text>
        <Text>Sabine Acker (links) ist Sozialpädagogin und arbeitetet als Schulsozialarbeiterin an der Luise-Bronner-Realschule in Heilbronn. In den letzten Monaten war es für sie schwer, den Zugang zu Kindern und Jugendlichen aufrecht zu erhalten.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "s80",
    height: sectionDefaultHeight,
    image: image75,
    content:
      <OuterTextContainer left><TextContainer>
        <Text underlined>Heilbronn, 22. Juni 2020</Text>
        <Text tall>Die Turnhalle der Luise-Bronner-Realschule wurde für die Englischprüfung einer neunten Klasse vorbereitet. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "Website-Ende",
    height: sectionDefaultHeight,
    image: image76,
    content:
      <OuterTextContainer right><TextContainer>
        <Text underlined>Stuttgart, 11. Oktober 2020</Text>
        <Text tall>Wenzel D. geht in Stuttgart spazieren. Mit dabei: Sein tragbares Sauerstoffgerät und ein Pulsoximeter, mit dem er die Sauerstoffsättigung seines Blutes misst. Gleichzeitig steigen die Zahlen der Corona-Infizierten erneut stark an. Seit dem Vortag gilt Stuttgart als Risikogebiet.</Text>
      </TextContainer></OuterTextContainer>
  }
];