import React from "react";
import ScrollMagic from "scrollmagic";
import {stack as Menu} from "react-burger-menu";
import CookieConsent from "react-cookie-consent";
import {
  ImageContainer,
  Image,
  ImagePreloader,
  Section
} from "../components/styledcomponents";
import {content} from "../etc/content";
import {DasProjekt} from "./dasprojekt";
import {DieZeitung} from "./diezeitung";
import {DerRaum} from "./derraum";
import {Imprint} from "./imprint";
import {Presse} from "./presse";

const menuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
    outline: "none",
  },
  bmBurgerBars: {
    background: '#aaa'
  },
  bmBurgerBarsHover: {
    background: '#fff'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    outline: "none"
  },
  bmCross: {
    background: '#aaa',
    outline: "none"
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    outline: "none"
  },
  bmMenu: {
    background: '#222',
    padding: '60px 20px 0',
    fontSize: '18px',
    outline: "none",
    width: 500
  },
  bmMorphShape: {
    fill: '#aaa',
    outline: "none"
  },
  bmItemList: {
    padding: '0.8em',
    outline: "none"
  },
  bmItem: {
    display: 'block',
    padding: "10px",
    color: "#aaa",
    outline: "none",
    cursor: "pointer"
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.4)',
  },
}

export class TIGIPMainView extends React.Component {

  constructor(){
    super();
    this.state = {
      currentImage: 1,
      img: null,
      showImage: false,
      currentScene: "section_dummy_",
      menuOpen: false
    };
    this.breakpoint = this.getBreakpoint();
    this.controller = new ScrollMagic.Controller();
    this.defaultDuration = window.innerHeight;
    this.onResize = this.onResize.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  getBreakpoint(){
    return window.innerWidth > 1000 ? "maxWidth" : window.innerWidth > 700 ? "mediumWidth" : "smallWidth";
  }

  getDurationForElement(id){
    return document.getElementById(id).offsetHeight;
  }

  onResize(){
    const newBreakpoint = this.getBreakpoint();
    if (newBreakpoint !== this.breakpoint){
      this.breakpoint = newBreakpoint;
      window.location.reload();
    }
  }

  onToggleMenu(state) {
    this.setState({menuOpen: state.isOpen})
  }

  closeMenu(){
    this.setState({menuOpen: false});
  }

  scrollTo(id){
    this.setState({currentPage: "home"});
    this.controller.scrollTo(id);
    this.closeMenu();
  }

  setPage(id){
    this.setState({currentPage: id});
    this.closeMenu();
  }

  onCookiesAccepted(){
    window.localStorage.setItem('consent', "true");
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  initMatomo(){
    if (!(window.localStorage.getItem("consent") === "false")){
      window._paq = window._paq || [];
      window._paq.push(['trackPageView']);
      (function () {
        let u = "//www.thereisgloryinprevention.de/analytics/";
        window._paq.push(['setTrackerUrl', u + 'matomo.php']);
        window._paq.push(['setSiteId', '1']);
        let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.src = u + 'matomo.js';
        s.parentNode.insertBefore(g, s);
      })();
    }
  }

  trackPageView(page){
    if (!(window.localStorage.getItem("consent") === "false")){
      const currentUrl = '/' + page;
      window._paq.push(['setCustomUrl', currentUrl]);
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    }
  }

  componentDidMount() {
    /* Matomo */
    this.initMatomo();

    /* resize handler */
    window.addEventListener('resize', this.onResize);

    /* content */
    const setState = this.setState.bind(this);
    const duration = this.defaultDuration;

    content.forEach((item, idx) => {
      const triggerElement = "#" + item.sectionId;
      const duration = this.getDurationForElement(item.sectionId);
      const triggerHook = 0.8;
      const nextItem = content[idx+1];
      const newState = {
        currentScene: item.sectionId,
        nextImg: nextItem?.image
      }
      new ScrollMagic.Scene({triggerElement: triggerElement, duration:duration, triggerHook: triggerHook})
        .on("enter leave", (e) => {
          if (e.type === "enter"){
            /* Matomo */
            const sectionId = item?.sectionId;
            if (sectionId && !sectionId.startsWith("s")){
              this.trackPageView(sectionId);
            }

            /* change content */
            setState(newState);
            const imageState = this.state.currentImage === 1 ?
              {
                img: item.image,
                imagePosition: item?.imagePosition,
                showImage: true,
                currentImage: 2
              }
              :
              {
                img2: item.image,
                imagePosition2: item?.imagePosition,
                showImage2: true,
                currentImage: 1
              };
            if (item.image && imageState) {
              setState(imageState);
            };
          } else {
            const newState = this.state.currentImage === 1 ? {showImage2: false} : {showImage: false};
            setState(newState);
          }
        })
        .addTo(this.controller);
    });

    /* re-direct to "Zeitung" */
    const queryString = window.location.search;
    if (queryString.startsWith("?zeitung")){
      this.setPage("Die Zeitung");
    } else if (queryString.startsWith("?projekt")){
      this.setPage("Das Projekt");
    }

  }

  render(){
    const {currentPage, currentScene, img, img2, imagePosition, imagePosition2, showImage, showImage2, nextImg, menuOpen} = this.state;
    const orientation = (window.innerWidth/window.innerHeight) > 1 ? "landscape" : "portrait";

    return <React.Fragment>
      <Menu right styles={menuStyles} isOpen={menuOpen} onStateChange={this.onToggleMenu}>
        <div onClick={(e) => {this.scrollTo("#Kapitel-1-Der-Lockdown")}}>Der Lockdown</div>
        <div onClick={(e) => {this.scrollTo("#Kapitel-2-Das-Testmobil")}}>Das Testmobil</div>
        <div onClick={(e) => {this.scrollTo("#Kapitel-3-Die-Pflege")}}>Die Pflege</div>
        <div onClick={(e) => {this.scrollTo("#Kapitel-4-Der-schwere-Verlauf")}}>Der schwere Verlauf</div>
        <div onClick={(e) => {this.scrollTo("#Kapitel-5-Die-neue-Normalitaet")}}>Die neue Normalität </div>
        <div><a href={"https://www.longcovidfoto.de"} target={"_blank"} className={"menuLink"}>Long Covid</a></div>
        <div></div>
        <div onClick={() => {this.setPage("Das Projekt")}}>Das Projekt</div>
        {/*<div onClick={() => {this.setPage("Die Zeitung")}}>Die Zeitung</div>*/}
        <div onClick={() => {this.setPage("Der öffentliche Raum")}}>Der öffentliche Raum</div>
        <div onClick={() => {this.setPage("Presse")}}>Presse</div>
        <div onClick={() => {this.setPage("Imprint")}}>Impressum / Datenschutz</div>
      </Menu>

      { currentPage === "Das Projekt" && <DasProjekt /> }
      { currentPage === "Die Zeitung" && <DieZeitung /> }
      { currentPage === "Der öffentliche Raum" && <DerRaum /> }
      { currentPage === "Presse" && <Presse /> }
      { currentPage === "Imprint" && <Imprint /> }

      <CookieConsent
        location="bottom"
        buttonText="Einverständnis erteilen"
        style={{ background: "#555" }}
        buttonStyle={{ color: "#000", fontSize: "13px", backgroundColor: "#fff" }}
        onAccept={() => { this.onCookiesAccepted()}}
      >
        Diese Website nutzt technisch notwendige Cookies und Cookies zur Erhebung anonymisierter Nutzungsstatistiken. Bitte lesen Sie dazu unsere <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => {this.setPage("Imprint")}}>Datenschutzerklärung</span>.
      </CookieConsent>

      <div>
        <ImageContainer>
          <Image src={img} showImage={showImage} imagePosition={imagePosition} orientation={orientation} />
        </ImageContainer>
        <ImageContainer>
          <Image src={img2} showImage={showImage2} imagePosition={imagePosition2} orientation={orientation} />
        </ImageContainer>

        <ImagePreloader src={nextImg} width={1} height={1} />

        <Section id="leadIn" style={{height: "40vh"}} show={currentScene === "leadIn"} />

        {
          content.map(item => {
            return <Section key={item.sectionId} id={item.sectionId} style={{height:item.height || "auto"}} show={currentScene === item.sectionId}>
              {item.content}
            </Section>
          })
        }
      </div>
    </React.Fragment>;
  }

}