import React from "react";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";

import fotoStenin from '../img/presse/screenshot_AndreiSteninInternationalPressPhotoContest.png';
import fotoMonopol from '../img/presse/screenshot_monopol.png';
import fotoStern from '../img/presse/screenshot_stern.png';
import fotoSZ from '../img/presse/screenshot_SZ.png';
import fotoStuttgarter from '../img/presse/screenshot_StuttgarterZeitung.png';
import fotoSWR from '../img/presse/screenshot_SWR2.png';

export class Presse extends React.Component {

  constructor() {
    super();
  }

  componentDidMount() {
    if (!(window.localStorage.getItem("consent") === "false")){
      window._paq.push(['setCustomUrl', "/Presse"]);
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    }
  }

  render() {
    return (
      <div style={{
        zIndex: 100,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "scroll",
        backgroundColor: "#000",
        paddingTop: 100,
        paddingBottom: 100
      }}>
        <OuterTextContainer>
          <TextOnlyContainer>

            <Text xl underlined style={{marginTop:50}}>Aus der Presse</Text>

            <Text tall style={{marginTop: 50}}>
              <b>Ehrenvolle Erwähnung beim Andrei Stenin International Press Photo Contest und Preis von Al Mayadeen TV</b>
              <br/><br/><a style={{color:"#fff"}} href="https://stenincontest.com/winners2021_p_series/20210615/368733.html">stenincontest.com</a>
              <br/><br/><img src={fotoStenin} style={{width: 400, maxWidth: "90%"}} />
            </Text>

            <Text tall style={{marginTop: 50}}>
              <b>Die harte Realität der Pandemie: Patrick Junker und sein berührendes Corona-Fotoprojekt</b>
              <br/>(SWR2, 28.1.2021)
              <br/><br/>"Die Corona-Pandemie beherrscht unser Leben, dennoch bleibt die Gefahr für die meisten abstrakt. Mit dem Fotoprojekt „There is glory in prevention“ möchte der Stuttgarter Fotojournalist Patrick Junker die Pandemie sichtbar machen."
              <br/><br/><a style={{color:"#fff"}} href="https://www.swr.de/swr2/kunst-und-ausstellung/die-harte-realitaet-der-pandemie-patrick-junker-und-sein-beruehrendes-corona-fotoprojekt-100.html">Link zum ganzen Artikel</a>
              <br/><br/><img src={fotoSWR} style={{width: 400, maxWidth: "90%"}} />
            </Text>

            <Text tall style={{marginTop: 50}}>
              <b>„Zeitgeschichte direkt vor der Haustür“</b>
              <br/>(Süddeutsche Zeitung, 8.1.2021)
              <br/><br/>"Der Fotograf Patrick Junker hat das Corona-Jahr in Bilder gefasst – und zeigt, was Menschen leisten, die dem Virus ganz nahe kommen."
              <br/><br/><a style={{color:"#fff"}} href="https://www.sueddeutsche.de/projekte/artikel/panorama/fotoprojekt-von-patrick-junker-glory-in-prevention-e241689/">Link zum ganzen Artikel</a>
              <br/><br/><img src={fotoSZ} style={{width: 400, maxWidth: "90%"}} />
            </Text>

            <Text tall style={{marginTop: 50}}>
              <b>Fotojournalist über Corona-Projekt: "Mir wurde Propaganda vorgeworfen"</b>
              <br/>(stern, 16.12.2020)
              <br/><br/>"Das Fotoprojekt "There is glory in prevention" erzählt von Licht und Schatten der Corona-Pandemie. Im Interview berichtet der Fotojournalist Patrick Junker über seine Arbeit mit den Patienten, was er über Corona-Leugner denkt – und warum er seine Kamera in einen Gefrierbeutel wickeln musste."
              <br/><br/><a style={{color:"#fff"}} href="https://www.stern.de/gesundheit/-there-is-glory-in-prevention---patrick-junker-erzaehlt-die-geschichten-der-pandemie-9530730.html">Link zum ganzen Artikel</a>
              <br/><br/><img src={fotoStern} style={{width: 400, maxWidth: "90%"}} />
            </Text>

            <Text tall style={{marginTop: 50}}>
              <b>"Bewegende Bilder zeigen den Corona-Alltag in Stuttgart"</b>
              <br/>(Stuttgarter Zeitung, 22. Oktober 2020)
              <br/><br/>"Es sind Bilder, die unter die Haut gehen – und einen noch nicht dagewesenen Blick auf den Alltag der Corona-Krise in Stuttgart werfen."
              <br/><br/><a style={{color:"#fff"}} href="https://www.stuttgarter-zeitung.de/inhalt.coronavirus-in-stuttgart-bewegenden-bilder-zeigen-den-corona-alltag-in-stuttgart.9b79c698-4990-4a39-8d41-1e48240a196a.html">Link zum ganzen Artikel</a>
              <br/><br/><img src={fotoStuttgarter} style={{width: 400, maxWidth: "90%"}} />
            </Text>

            <Text tall style={{marginTop: 50}}>
              <b>"Der Alltag, der nicht alltäglich war"</b>
              <br/>(Monopol, 26. Oktober 2020)
              <br/><br/>"In Stuttgart hängen Bilder von Alltagsszenen aus den ersten Monaten der Corona-Pandemie. Der Fotograf Patrick Junker will damit ein Zeichen für Zusammenhalt setzen."
              <br/><br/><a style={{color:"#fff"}} href="https://www.monopol-magazin.de/der-alltag-der-nicht-alltaeglich-war">Link zum ganzen Artikel</a>
              <br/><br/><img src={fotoMonopol} style={{width: 400, maxWidth: "90%"}} />
            </Text>

          </TextOnlyContainer>
        </OuterTextContainer>
      </div>
    );
  }
}
