import React from "react";
import {
  Text, OuterTextContainer, PageContainer,
} from "../components/styledcomponents";
import { SRLWrapper } from "simple-react-lightbox";
import image01 from "../img/Zeitung/01VorschauZeitung.jpg";
import image02 from "../img/Zeitung/03VorschauZeitung.jpg";
import image03 from "../img/Zeitung/04VorschauZeitung.jpg";
import image04 from "../img/Zeitung/06VorschauZeitung.jpg";
import image05 from "../img/Zeitung/07VorschauZeitung.jpg";
import image06 from "../img/Zeitung/08VorschauZeitung.jpg";
import image07 from "../img/Zeitung/09VorschauZeitung.jpg";
import image08 from "../img/Zeitung/11VorschauZeitung.jpg";
import image09 from "../img/Zeitung/12VorschauZeitung.jpg";

import styled from "styled-components";

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

const GalleryImage = styled.img`
  width: 250px;
  margin-right: 10px;
  cursor: zoom-in;
`;

export class DieZeitung extends React.Component {

  constructor() {
    super();
  }

  componentDidMount() {
    if (!(window.localStorage.getItem("consent") === "false")){
      window._paq.push(['setCustomUrl', "/Die-Zeitung"]);
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    }
  }

  render() {
    return (
      <div style={{
        zIndex: 100,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "scroll",
        backgroundColor: "#000",
        paddingTop: 100,
        paddingBottom: 100
      }}>
        <OuterTextContainer>
          <PageContainer>

            <Text xl underlined>Die Zeitung</Text>

            <div style={{padding: 40}}>
              {/*<img src={image01} width={"100%"} />*/}
              <Video autoPlay loop muted controls={[]}>
                <source src="./TIGIP_Facebook_01.mp4" type="video/mp4" />
              </Video>
            </div>

            <Text tall>
              »There is glory in prevention« soll die Solidarität und den gesellschaftlichen Zusammenhalt in der  Corona-Pandemie stärken. Trotz aller Maßnahmen und Verbote: Das Verhalten der Bevölkerung wird entscheidend für den Verlauf der Pandemie sein. Das Crossmediale Projekt  soll im öffentlichen Raum und online daran erinnern, was wir als Gesellschaft in der ersten Welle der Pandemie erreicht haben.
            </Text>

            <Text tall>
              Die Projekt-Zeitung zu »There is glory in prevention« ist kostenfrei. Eigentlich sollten die 6000 Exemplare in Stuttgarter Stadtbibliotheken, Galerien und der Gastronomie verteilt werden. Aufgrund des zweiten Lockdowns haben wir nur begrenzte Möglichkeiten, die Zeitungen auszulegen.
              Daher startet das Team von »There is glory in prevention« diese Versandaktion.
            </Text>

            <Text tall>
              Auflage: 6000 Exemplare - Format: 23,5 cm x 31,5 cm - Papier: 60 g/m Zeitungsdruckpapier aufgebessert, ISO 79%
              <br/>
              Fotos: Patrick Junker
              <br/>
              Text: Zeitenspiegel Reportagen
              <br/>
              Design: Bareis + Nicolaus
            </Text>

            <div style={{padding: 40}}>
              <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="JN6MA3K5F9TCE" />
                <input type="image" src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </div>

            <Text tall>
              Am 18. Dezember werden die Spenden für die Zeitung  gesammelt an die Künstler*innen Soforthilfe überwiesen. Die Kosten für Versand, Verpackung und Paypal werden von der Spende abgezogen. An dieser Stelle veröffentlichen wir die Höhe des gesammelten Betrags.
              Achtung: Sollten Sie eine Spendenquittung benötigen, dann bitte direkt an die Künstler*innen Soforthilfe spenden - <a style={{color:"white"}} href="https://kuenstlersoforthilfe-stuttgart.de">kuenstlersoforthilfe-stuttgart.de</a> - wir können keine Spendenquittungen ausstellen und werden keine Spendenquittung von der Künstler*innen Soforthilfe anfordern.
            </Text>

            <Text tall>
              Orte, an denen die Zeitungen bereits ausliegen:<br/>
              Buchhandlung Walther König, Kunstmuseum, Kleiner Schloßplatz 1<br/>
              Raupe Immersatt, Johannesstraße 97<br/>
              KulturKiosk, Parkhaus Züblin, Lazarettstraße 5 (Aktuell eingeschränkte Öffnungszeiten)
            </Text>

            <Text tall>
              Website: <a style={{color:"white"}} href="https://www.patrick-junker.com">www.patrick-junker.com</a>
              <br/>
              Instagram: <a style={{color:"white"}} href="https://www.instagram.com/patrick__junker/">@patrick__junker</a>
            </Text>

            <div style={{padding: 40}}>
              <SRLWrapper options={{buttons: {showDownloadButton: false}}}>
                <GalleryImage src={image01} />
                <GalleryImage src={image02} />
                <GalleryImage src={image03} />
                <GalleryImage src={image04} />
                <GalleryImage src={image05} />
                <GalleryImage src={image06} />
                <GalleryImage src={image07} />
                <GalleryImage src={image08} />
                <GalleryImage src={image09} />
              </SRLWrapper>
            </div>

          </PageContainer>
        </OuterTextContainer>
      </div>
    );
  }
}
