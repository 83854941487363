import React from "react";
import {
  Text, OuterTextContainer, PageContainer,
} from "../components/styledcomponents";

export class DasProjekt extends React.Component {

  constructor() {
    super();
  }

  componentDidMount() {
    if (!(window.localStorage.getItem("consent") === "false")){
      window._paq.push(['setCustomUrl', "/Das-Projekt"]);
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    }
  }

  render() {
    return (
      <div style={{
        zIndex: 100,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "scroll",
        backgroundColor: "#000",
        paddingTop: 100,
        paddingBottom: 100
      }}>
        <OuterTextContainer>
          <PageContainer>

            <Text xl underlined>Live-Interview im KULTUR KIOSK<br/></Text>
            <Text tall>Am <b>17. Dezember um 19:30 Uhr</b> wird Patrick Junker in einem <b>Live-Interview mit Sara Dahme im KULTUR KIOSK</b> Einblicke in seine Arbeit geben.</Text>

            <div style={{padding: 40}}>
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/BKJuVAcnlcg" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </div>

            <Text xl underlined>Der Fotograf Patrick Junker erzählt im Interview von seiner Arbeit<br/></Text>

            <Text tall><b><i><br/>Seit März fotografieren Sie Menschen in der Corona-Krise: Patienten und ihre
              Angehörige, Pflegekräfte, Ärzte, den veränderten Alltag. Warum?</i></b></Text>
            <Text tall><b>Patrick Junker:</b><br/>Zu Beginn der Corona-Krise sahen wir vor allem einfache Symbolbilder:
              Test-Stäbchen, Masken, Desinfektionsmittel. Viele Motive stammten aus fernen Ländern, zeigten etwa die
              Situation in China. Das alles schien unseren Alltag kaum zu berühren. Doch dann rückte die Pandemie immer
              näher, betraf plötzlich alle Bereiche unseres Lebens. Sie blieb jedoch unsichtbar, kaum greifbar. Dem
              wollte ich etwas entgegensetzen. Ich wollte Menschen in sogenannten systemrelevanten Berufen abbilden, die
              neue Normalität in Zeiten von Corona. Wichtig war mir dabei, den Fokus auf Menschen zu legen, die nah dran
              sind am Virus: also auf Erkrankte, an denen die Pandemie sichtbar wird. Aber auch auf die, die durch ihre
              Arbeit mit Betroffenen in Berührung kommen. Ich möchte, dass die Betrachter die Situation nachfühlen
              können. Was mich darüber hinaus interessiert: Wie geht es diesen Menschen? Und wie blicken wir in zehn
              Jahren auf die jetzige Situation? Bilder können dabei einen wichtigen Beitrag leisten, sie sind ein Stück
              Erinnerungskultur.</Text>

            <Text tall><b><i>„There is glory in prevention“ ist der Titel Ihres Projekts. Was ist damit gemeint?</i></b></Text>
            <Text tall>Mir geht es darum zu dokumentieren, was Pflegekräfte, Ärzte, aber auch wir alle in den
              vergangenen Monaten geleistet haben. Die Corona-Krise zeigt uns das Präventionsparadox auf. Maßnahmen, die
              für eine Gemeinschaft hohen Nutzen bringen, bringen dem Einzelnen oft nur wenig. Doch wenn viele sie
              beherzigen, nutzen sie allen. Leider ist es in der Realität so, dass der Spruch „There is no glory in
              prevention“ eher zutrifft. Also: „Es gibt kein Lob für die Vorsorge.“ Denn auch wenn eine Maßnahme
              wirkungsvoll ist, ist ihr Nutzen im Nachhinein oft nicht mehr zu erkennen. Darum erntet Vorbeugen wenig
              Ruhm. Es bleiben Fragen, für die es keine Antwort gibt. Was hätten wir verhindern können, wenn wir von
              Anfang an auf Masken gesetzt hätten, wenn wir besser vorbereitet gewesen wären auf eine Pandemie? Und was
              wäre ohne all die Maßnahmen passiert, die Kontaktbeschränkungen? Wie schlimm es hätte werden können,
              können wir nur durch den Blick in andere Länder verstehen.</Text>

            <Text tall><b><i>Wie haben Sie es geschafft, ausgerechnet während einer Pandemie Zutritt zu
              Intensivstationen zu bekommen?</i></b></Text>
            <Text tall>Als Fotojournalist hatte ich das Glück, hinter Türen schauen zu können, die vielen verschlossen blieben.
              Oft erhielt ich Ausnahmegenehmigungen, allerdings immer unter strengen Auflagen. Viele Krankenhäuser haben
              sich entschieden, möglichst wenigen Menschen Zutritt zu gewähren, um niemanden zu gefährden. In Kliniken
              zu fotografieren, ist ohnehin heikel, vor allem mit Blick auf den Datenschutz. Schon zuvor hatte ich immer
              wieder die Möglichkeit, zuletzt für eine Langzeitreportage über Organspende für das Magazin stern. Mit
              diesen Bildern als Referenz habe ich bei verschiedenen Kliniken angefragt, wieder im Auftrag vom stern.
              Das Personal des Marienhospitals hat mir schließlich vertraut.</Text>

            <Text tall><b><i>Was hat Sie dabei besonders beeindruckt?</i></b></Text>
            <Text tall>Ich habe engagierte Menschen kennengelernt, die alles geben – nicht nur während dieser Pandemie.
              Ich wollte zeigen, was Pflegekräfte und Ärzte nicht nur in diesem Ausnahmezustand leisten. Was treibt sie
              täglich an? Wie gehen sie mit dem Risiko um? Die Anerkennung und Wertschätzung fehlen oft, die
              Arbeitsbelastung ist immens. Viele der Frauen und Männer sagten mir, sie hätten den Eindruck, dass während
              der Corona-Krise mehr über sie statt mit ihnen gesprochen wurde. Menschen applaudierten von Balkonen,
              Politiker sangen Loblieder auf „Heldinnen und Helden des Alltags“. Jetzt wird der Notstand besonders
              deutlich. Dabei vergessen wir, dass sich der Zustand der Pflege in Deutschland in einer Dauerkrise
              befindet.</Text>

            <Text tall><b><i>Was waren für Sie persönliche Herausforderungen?</i></b></Text>
            <Text tall>Besonders streng waren die Vorkehrungen auf Covid-19-Stationen: Ich trug wie das Personal einen
              Schutzanzug. Die Kamera musste ständig desinfiziert werden, was schwer war wegen all der Ecken und Kanten.
              Darum hüllte ich sie in Gefrierbeutel, die ich wie den Anzug immer wechselte, sobald ich einen anderen
              Raum betrat. Manchmal bis zu zwanzig Mal pro Schicht. Während meiner Arbeit in den vergangenen Monaten
              habe ich immer darauf geachtet, möglichst wenig Kontakt zu anderen zu haben, abgesehen von meiner Freundin
              und unseren beiden Mitbewohnern. Ich trug immer eine Maske, achtete auf den Abstand zu den Porträtierten.
              Als ich beispielsweise Mitte März im Supermarkt Risikopatienten fotografierte, die dort vor Ladenöffnung
              einkaufen durften, bemerkte ich, wie sie im Gespräch automatisch immer näher rückten. Ein menschlicher
              Impuls. Sich an diesen distanzierten Umgang zu gewöhnen, fällt schwer, auch mir.</Text>

            <Text tall><b><i>Wie geht es für Sie und das Projekt jetzt weiter?</i></b></Text>
            <Text tall>Mit den Porträtierten bleibe ich in Kontakt. Auch in den kommenden Monaten möchte ich ihren
              Alltag festhalten und das Projekt erweitern: Wie geht es beispielsweise psychisch kranken Menschen, die
              sich ohnehin einsam fühlen? Wie gehen Restaurant- und Clubbetreiber mit dem wirtschaftlichen Einbruch um?
              Was passiert, wenn mehr und mehr Unternehmen pleite gehen? Wichtig ist mir dabei, nicht die Menschen
              gegeneinander auszuspielen. Viele brauchen momentan Hilfe. Gerade jetzt müssen wir uns gegenseitig
              zuhören.</Text>

            <Text xl underlined>Der Fotograf</Text>
            <Text tall>Patrick Junker, geb. 1991, ist freiberuflicher Fotojournalist und lebt in Stuttgart. Er hat im
              August 2020 seinen Bachelor in Fotojournalismus und Dokumentarfotografie an der Hochschule Hannover
              absolviert. Das Fotoprojekt „There is glory in prevention“ war seine Abschlussarbeit.</Text>
            <Text tall>Für seine Reportagen über Organtransplantationen wurde er mit dem Hansel-Mieth-Preis, dem Grimme-
              Online Award und dem DGPh-Preis für Wissenschaftsfotografie ausgezeichnet. Patrick Junker arbeitet für
              Redaktionen wie Brand Eins, Capital, CNN International, DER SPIEGEL, DIE ZEIT, fluter online, Frankfurter
              Allgemeine Zeitung, GEO Wissen, Handelsblatt und stern.</Text>
            <Text tall>
              Website: <a style={{color:"white"}} href="https://www.patrick-junker.com">www.patrick-junker.com</a>
              <br/>
              Instagram: <a style={{color:"white"}} href="https://www.instagram.com/patrick__junker/">@patrick__junker</a>
            </Text>

          </PageContainer>
        </OuterTextContainer>
      </div>
    );
  }
}
