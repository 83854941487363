import React, { useState } from 'react';
import {
  Text, OuterTextContainer, PageContainer,
} from "../components/styledcomponents";
import styled from "styled-components";
import { SRLWrapper } from "simple-react-lightbox";
import image01 from "../img/Galerie/01_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image02 from "../img/Galerie/02_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image03 from "../img/Galerie/03_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image04 from "../img/Galerie/04_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image05 from "../img/Galerie/05_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image06 from "../img/Galerie/06_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image07 from "../img/Galerie/07_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image08 from "../img/Galerie/08_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image10 from "../img/Galerie/10_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image11 from "../img/Galerie/11_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image12 from "../img/Galerie/12_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image13 from "../img/Galerie/13_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image14 from "../img/Galerie/14_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image15 from "../img/Galerie/15_TIGIP_Aktionen_sRGB_sRGB.jpg";
import image17 from "../img/Galerie/17_TIGIP_Aktionen_sRGB_sRGB.jpg";

const GalleryImage = styled.img`
  width: 250px;
  margin-right: 10px;
  cursor: zoom-in;
`;

export class DerRaum extends React.Component {

  constructor() {
    super();
    this.state = {
      toggler: false
    }
  }

  componentDidMount() {
    if (!(window.localStorage.getItem("consent") === "false")){
      window._paq.push(['setCustomUrl', "/Der-Raum"]);
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    }
  }

  render() {
    return (
      <div style={{
        zIndex: 100,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "scroll",
        backgroundColor: "#000",
        paddingTop: 100,
        paddingBottom: 100
      }}>
        <OuterTextContainer>
          <PageContainer>
            {/*<Text xl underlined>Vernissage<br/>vom 23. Oktober 2020<br/></Text>*/}
            {/*<div style={{padding: 40}}>*/}
            {/*  <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/D-M5zymEPdY" frameBorder="0"*/}
            {/*          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*          allowFullScreen></iframe>*/}
            {/*</div>*/}

            <Text xl underlined>Public Slideshow</Text>
            <div style={{padding: 40}}>
              <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/nwSKcu8MQGY" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div>

            <Text xl underlined>Galerie</Text>
            <div style={{padding: 40}}>
              <SRLWrapper options={{buttons: {showDownloadButton: false}}}>
                <GalleryImage src={image02} />
                <GalleryImage src={image03} />
                <GalleryImage src={image04} />
                <GalleryImage src={image07} />
                <GalleryImage src={image08} />
                <GalleryImage src={image10} />
                <GalleryImage src={image11} />
                <GalleryImage src={image12} />
                <GalleryImage src={image13} />
                <GalleryImage src={image14} />
                <GalleryImage src={image15} />
                <GalleryImage src={image17} />
                <GalleryImage src={image01} />
                <GalleryImage src={image05} />
                <GalleryImage src={image06} />
              </SRLWrapper>
            </div>


            <Text xl underlined>Der Fotograf</Text>
            <Text tall>Patrick Junker, geb. 1991, ist freiberuflicher Fotojournalist und lebt in Stuttgart. Er hat im
              August 2020 seinen Bachelor in Fotojournalismus und Dokumentarfotografie an der Hochschule Hannover
              absolviert. Das Fotoprojekt „There is glory in prevention“ war seine Abschlussarbeit.</Text>
            <Text tall>Für seine Reportagen über Organtransplantationen wurde er mit dem Hansel-Mieth-Preis, dem Grimme-
              Online Award und dem DGPh-Preis für Wissenschaftsfotografie ausgezeichnet. Patrick Junker arbeitet für
              Redaktionen wie Brand Eins, Capital, CNN International, DER SPIEGEL, DIE ZEIT, fluter online, Frankfurter
              Allgemeine Zeitung, GEO Wissen, Handelsblatt und stern.</Text>
            <Text tall>
              Website: <a style={{color:"white"}} href="https://www.patrick-junker.com">www.patrick-junker.com</a>
              <br/>
              Instagram: <a style={{color:"white"}} href="https://www.instagram.com/patrick__junker/">@patrick__junker</a>
            </Text>

          </PageContainer>
        </OuterTextContainer>
      </div>
    );
  }
}
